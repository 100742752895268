import React from 'react';
import { MDBDataTableV5 } from 'mdbreact';

import { useTranslation } from 'react-i18next';

export default function Basic(props) {

  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

  let datatable;
    [datatable] = React.useState('');
  
  if(props.deviceData!==undefined){

    userAttributes = [];
    for (const key of Object.keys(props.deviceData)) {
      
      userAttributes.push({
          status: props.deviceData[key]['currentlyOnline']===true?<div className="active-circle"></div>:<div className="inactive-circle"></div>,
          type: props.deviceData[key]['DeviceType'],
          deviceName: props.deviceData[key]['UserDefinedHostName'],
          hostName: props.deviceData[key]['HostName'],
          ipaddress: props.deviceData[key]['Framed-IP-Address'][0],
          lastConnected: props.deviceData[key]['mostRecentUseUTC.ISO8601'] !== undefined ? Intl.DateTimeFormat('en-CA',{
                                                  year: 'numeric',
                                                  month: '2-digit',
                                                  day: '2-digit',
                                                  hour: 'numeric',
                                                  minute: 'numeric'
                                                }).format(new Date(props.deviceData[key]['mostRecentUseUTC.ISO8601'])) : "Unknown"

        })
      i = i + 1;
      
    }
    
  }
  
    datatable = ({
    columns: [
      
      {
        label: t('managedevicesdatatable.status'),
        field: 'status'
      },
      {
        label: t('managedevicesdatatable.type'),
        field: 'type'
      },
      {
        label: t('managedevicesdatatable.name'),
        field: 'deviceName'
      },
      {
        label: "Host Name",
        field: 'hostName',
      },
      {
        label: t('managedevicesdatatable.ipaddress'),
        field: 'ipaddress',
      },
      {
        label: t('managedevicesdatatable.lastconnected'),
        field: 'lastConnected'
      }
    ],
    rows: userAttributes
  });
  return <MDBDataTableV5 
            hover 
            data={datatable} 
            searchBottom={false} 
            paging={false}
            className="profilesData"
            info={false}
            noRecordsFoundLabel={t('recentservice.nomatches')}
            responsive
  />;
}