import React, { Component } from "react";

import { MDBBtn, MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import { withTranslation } from "react-i18next";

class AddWebhook extends Component {
	constructor(props) {
		super(props);

		this.state = {
			webhookURL: this.props.webhookURL
		};
	}

	submitAddWebhook = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";

		if (
			this.state.webhookURL !== undefined
		) {
			this.props.submitAddWebhook(
				this.state.webhookURL
			);
		}
	};

	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {

		const { t } = this.props;
		return (
			<>
				<MDBModalHeader toggle={this.props.toggleAddWebhook}>

					<h4>{this.state.webhookURL!==undefined?"Modify Webhook":"Add Webhook"}</h4>
					<p>{t("addnewresident.required")}</p>
				</MDBModalHeader>
				<MDBModalBody>
					<form className="needs-validation" onSubmit={this.submitAddWebhook} noValidate>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									Webhook URL*
								</label>
								<input
									required
									id="webhookURL"
									name="webhookURL"
									className="form-control"
									onChange={this.changeHandler}
									value={this.state.webhookURL}
								/>
								<div className="invalid-feedback">Please provide a value.</div>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								{this.props.add_webhook === true ? (
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : (
									this.props.webhookURL!==undefined?"Save Changes":"Add Webhook"
								)}
							</MDBBtn>
							<MDBBtn color="primary" onClick={this.props.toggleAddWebhook}>
								Close
							</MDBBtn>
						</div>
					</form>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(AddWebhook);