import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBIcon,
  MDBBtn,
  toast,
  MDBSelect,
  MDBSelectOption,
  MDBSelectOptions,
  MDBSelectInput,
} from "mdbreact";
import axios from "../../../axios";
import ResidentManagementNavigation from "../Navigation/ResidentManagementNavigation/ResidentManagementNavigation";
import GuestManagementNavigation from "../Navigation/GuestManagementNavigation/GuestManagementNavigation";
import SupportNavigation from "../Navigation/SupportNavigation/SupportNavigation";
import PropertySettingsNavigation from "../Navigation/PropertyManagementNavigation/PropertyManagementNavigation";
import Logo from "../../../components/Logo/Logo";
import { withTranslation } from "react-i18next";
import LanguageSwitch from "../Navigation/LanguageSwitch/LanguageSwitch";
import MyAccount from "../../../components/VenueMgmt/MyAccount/MyAccount";
import Toggle from "react-toggle";
import brandsData from "../../../brands/common/brands.json";
class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLogged: false,
      modalResidentManagement: this.props.modalResidentManagement,
      modalGuestManagement: false,
      modalSupport: false,
      modalPropertySettings: false,
      modalMyAccount: false,
      venueList2: [],
      keyPoolForm: [],
      venueListName: [],
      darkmode: localStorage.getItem("darkMode") === "true" ? true : false,
    };
  }
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSelectChange = (value) => {
    var venue = document.getElementsByClassName("venueSelected")[0].value;
    var lDiv = document.createElement("div");
    document.body.appendChild(lDiv);
    lDiv.style.fontSize = "" + 20 + "px";
    lDiv.style.position = "absolute";
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;
    lDiv.innerHTML = venue;
    var lResult = {
      width: lDiv.clientWidth,
      height: lDiv.clientHeight,
    };
    document.body.removeChild(lDiv);
    lDiv = null;
    document.getElementById("selectVenue").style.width =
      lResult["width"] + 30 + "px";
    this.props.updateName(venue, value[0]);
    this.setState({ selectedID: value[0] });
  };
  toggleResidentManagement = () => {
    this.setState({
      modalResidentManagement: !this.state.modalResidentManagement,
    });
  };
  toggleGuestManagement = () => {
    this.setState({
      modalGuestManagement: !this.state.modalGuestManagement,
    });
  };
  toggleSupport = () => {
    this.setState({
      modalSupport: !this.state.modalSupport,
    });
  };
  togglePropertySettings = () => {
    this.setState({
      modalPropertySettings: !this.state.modalPropertySettings,
    });
  };
  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  toggleResetPassword = () => {
    this.setState({
      modalResetPassword: !this.state.modalResetPassword,
    });
  };
  toggleMyAccount = () => {
    this.setState({
      modalMyAccount: !this.state.modalMyAccount,
    });
  };
  resetPassword = () => {
    let data;
    data = {
      EmailAddress: sessionStorage.getItem("email"),
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    axios
      .post("/subscriberconsole/initiateResetConsolePassword", data, {
        headers: {},
      })
      .then((response) => {
        this.toggleResetPassword();
        toast.success(
          "Password reset initiated, please check your email for instructions!",
          {
            closeButton: false,
          }
        );
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
      });
  };
  componentDidMount() {
    this._isMounted = true;
    this.getVenues();
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute("data-theme", "darkMode-" + this.state.darkmode);
  }
  getVenues = (selectedID) => {
    this.setState({
      attributeForm: [],
    });
    let body = { AccessToken: sessionStorage.getItem("AccessToken") };
    axios
      .post("/venuemgmtconsole/venues/listVenues", body, {
        headers: {},
      })
      .then((response) => {
        var formArray = [];
        let optionTemplateKeyPools = [];
        Object.keys(response.data.VenueList).map((oneKey, i) => {
          if (sessionStorage.getItem("venueID") === oneKey) {
            optionTemplateKeyPools.push(
              <MDBSelectOption selected value={oneKey}>
                {response.data.VenueList[oneKey]["VenueName"]}
              </MDBSelectOption>
            );
          } else if (selectedID === oneKey) {
            optionTemplateKeyPools.push(
              <MDBSelectOption selected value={oneKey}>
                {response.data.VenueList[oneKey]["VenueName"]}
              </MDBSelectOption>
            );
          } else if (selectedID === undefined && i === 0) {
            optionTemplateKeyPools.push(
              <MDBSelectOption selected value={oneKey}>
                {response.data.VenueList[oneKey]["VenueName"]}
              </MDBSelectOption>
            );
          } else {
            optionTemplateKeyPools.push(
              <MDBSelectOption value={oneKey}>
                {response.data.VenueList[oneKey]["VenueName"]}
              </MDBSelectOption>
            );
          }
          return true;
        });
        formArray.push(
          <MDBSelect id="selectVenue" getValue={this.handleSelectChange}>
            <MDBSelectInput
              className="selectInput venueSelected"
              selected="Choose your venue"
            />
            <MDBSelectOptions search>{optionTemplateKeyPools}</MDBSelectOptions>
          </MDBSelect>
        );
        this.setState({
          attributeForm: formArray,
        });
      })
      .catch((error) => {
        this.setState({ error: true });
        this.setState({ showError: true });
      });
  };
  onSignOutClick = () => {
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute("data-theme", "darkMode-false");
    let key;
    let keysToRemove = [
      "RefreshToken",
      "userEmail",
      "networkAdmin",
      "subscriberToken",
      "isAdmin",
      "loggedInNO",
      "phoneNumber",
      "lastName",
      "AccessToken",
      "leasingAgent",
      "venueAdmin",
      "adminOf",
      "email",
      "accountManager",
      "firstName",
      "customerSupport",
      "venueName",
      "venueID",
    ];

    for (key of keysToRemove) {
      sessionStorage.removeItem(key);
    }

    localStorage.removeItem("customBrand");
    this.setState({ isLogged: false });
  };
  handleSwitchChange = (nr) => () => {
    this.setState({
      darkmode: !this.state.darkmode,
    });
    localStorage.setItem("darkMode", !this.state.darkmode);
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute("data-theme", "darkMode-" + !this.state.darkmode);
  };
  render() {
    let address = (
      <div className="address">
        <p>{this.props.address1}</p>
        <p>{this.props.address2}</p>
        <p>
          {this.props.city}, {this.props.state} {this.props.postalCode}
        </p>
      </div>
    );
    let showTranslation;
    let showTour;
    brandsData.forEach(({ name, url, flag_showTour, flag_showTranslation }) => {
      if (url.includes(window.location.hostname)) {
        showTranslation = flag_showTranslation;
        showTour = flag_showTour;
      }
    });
    const { t } = this.props;
    let showNetworkOpAdmin = true;
    let loginURL;
    sessionStorage.getItem("fromAuthToken")
      ? (loginURL = "/logout")
      : (loginURL = sessionStorage.getItem("loginPage"));
    return (
      <>
        {/* {this.state.showError ? 
			<MDBModal
			isOpen={true}
			size="md"
			className="form"
			centered
			>
			<MDBModalHeader className="error">
				<MDBIcon icon="exclamation-triangle" />Error
			</MDBModalHeader>
			<MDBModalBody className="error">
						<h4>An unknown error has occurred!</h4>
						<MDBBtn color="danger" onClick={() => window.location.reload(false)}>
							Try again
						</MDBBtn>
			</MDBModalBody>
			</MDBModal>
		:""} */}
        <MDBModal
          isOpen={this.state.modalResetPassword}
          toggle={this.toggleResetPassword}
          size="sm"
          className="smallModal"
          centered
        >
          <MDBModalHeader toggle={this.toggleResetPassword}>
            <h4>Reset Password</h4>
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBBtn
                onClick={this.resetPassword}
                color="danger"
                block
                bsSize="large"
                type="submit"
              >
                Reset Password
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalMyAccount}
          toggle={this.toggleMyAccount}
          size="lg"
          className="form"
          centered
        >
          <MyAccount
            toggleMyAccount={this.toggleMyAccount}
            allData={this.props.allData}
            listKeys={this.props.listKeys}
            SessionList={this.props.SessionList}
            keys={this.props.keys}
            venueName={this.props.venueName}
          />
        </MDBModal>
        <>
          <MDBNavbar color="" dark expand="md">
            <div class="Logo first-step" darkmode="false">
              {<img src={this.props.logo} alt="Logo" />}
            </div>
            <MDBNavbarNav>
              <div className="venue">
                <div className="venueName">{this.state.attributeForm}</div>
                {address}
              </div>
              <div className="mainNavigation">
                {this.props.venueType === "HOTSPOT" ? (
                  <>
                    <MDBNavItem className="eighth-step">
                      <MDBNavLink
                        to="#"
                        disabled={
                          this.props.allActiveData === undefined ? true : false
                        }
                        className="firstNav"
                        onClick={this.toggleGuestManagement}
                      >
                        {this.props.venueType === "HOTSPOT"
                          ? "Subscribers"
                          : t("navigation.guests")}
                      </MDBNavLink>
                      <MDBModal
                        isOpen={this.state.modalGuestManagement}
                        toggle={this.toggleGuestManagement}
                        size="lg"
                        centered
                      >
                        <GuestManagementNavigation
                          toggleGuestManagement={this.toggleGuestManagement}
                          venueNameSelected={this.props.selectedName}
                          keypoolID={this.props.keypoolID}
                          venueID={this.props.selectedID}
                          venueListName={this.state.venueListName}
                          updateName={this.props.updateName}
                          guestData={this.props.guestData}
                          guestDataExpiring={this.props.guestDataExpiring}
                          loading={this.props.loading}
                          venueType={this.props.venueType}
                          getVenues={this.getVenues}
                        />
                      </MDBModal>
                    </MDBNavItem>
                    <MDBNavItem className="seventh-step">
                      <MDBNavLink
                        to="#"
                        disabled={
                          this.props.allActiveData === undefined ? true : false
                        }
                        onClick={this.toggleResidentManagement}
                        id="residentModalLink"
                      >
                        {this.props.venueType === "HOTSPOT"
                          ? "Local Policies"
                          : t("navigation.residents")}
                      </MDBNavLink>
                      <MDBModal
                        isOpen={this.state.modalResidentManagement}
                        toggle={this.toggle}
                        size="lg"
                        centered
                      >
                        <ResidentManagementNavigation
                          toggle={this.toggleResidentManagement}
                          venueNameSelected={this.props.selectedName}
                          keypoolID={this.props.keypoolID}
                          venueID={this.props.selectedID}
                          venueListName={this.state.venueListName}
                          updateName={this.props.updateName}
                          activeData={this.props.activeData}
                          allActiveData={this.props.allActiveData}
                          expirations={this.props.expirations}
                          loading={this.props.loading}
                          venueType={this.props.venueType}
                          availablePlans={this.props.availablePlans}
                          getVenues={this.getVenues}
                        />
                      </MDBModal>
                    </MDBNavItem>
                  </>
                ) : (
                  <>
                    <MDBNavItem className="seventh-step">
                      <MDBNavLink
                        to="#"
                        disabled={
                          this.props.allActiveData === undefined ? true : false
                        }
                        className="firstNav residentModalLink"
                        onClick={this.toggleResidentManagement}
                      >
                        {this.props.venueType === "HOTSPOT"
                          ? "Subscribers"
                          : t("navigation.residents")}
                      </MDBNavLink>
                      <MDBModal
                        isOpen={this.state.modalResidentManagement}
                        toggle={this.toggle}
                        size="lg"
                        centered
                      >
                        <ResidentManagementNavigation
                          toggle={this.toggleResidentManagement}
                          venueNameSelected={this.props.selectedName}
                          keypoolID={this.props.keypoolID}
                          venueID={this.props.selectedID}
                          venueListName={this.state.venueListName}
                          updateName={this.props.updateName}
                          activeData={this.props.activeData}
                          allActiveData={this.props.allActiveData}
                          expirations={this.props.expirations}
                          loading={this.props.loading}
                          venueType={this.props.venueType}
                          availablePlans={this.props.availablePlans}
                          getVenues={this.getVenues}
                        />
                      </MDBModal>
                    </MDBNavItem>
                    <MDBNavItem className="eighth-step">
                      <MDBNavLink
                        to="#"
                        disabled={
                          this.props.allActiveData === undefined ? true : false
                        }
                        onClick={this.toggleGuestManagement}
                      >
                        {this.props.venueType === "HOTSPOT"
                          ? "Local Policies"
                          : t("navigation.guests")}
                      </MDBNavLink>
                      <MDBModal
                        isOpen={this.state.modalGuestManagement}
                        toggle={this.toggleGuestManagement}
                        size="lg"
                        centered
                      >
                        <GuestManagementNavigation
                          toggleGuestManagement={this.toggleGuestManagement}
                          venueNameSelected={this.props.selectedName}
                          keypoolID={this.props.keypoolID}
                          venueID={this.props.selectedID}
                          venueListName={this.state.venueListName}
                          updateName={this.props.updateName}
                          guestData={this.props.guestData}
                          guestDataExpiring={this.props.guestDataExpiring}
                          loading={this.props.loading}
                          venueType={this.props.venueType}
                          getVenues={this.getVenues}
                        />
                      </MDBModal>
                    </MDBNavItem>
                  </>
                )}
                <MDBNavItem className="ninth-step">
                  <MDBNavLink to="#" onClick={this.toggleSupport}>
                    {t("navigation.support")}
                  </MDBNavLink>
                  <MDBModal
                    isOpen={this.state.modalSupport}
                    toggle={this.toggleSupport}
                    size="lg"
                    centered
                  >
                    <SupportNavigation
                      toggleSupport={this.toggleSupport}
                      selectedName={this.props.selectedName}
                      venueListName={this.state.venueListName}
                      updateName={this.props.updateName}
                      activeData={this.props.activeData}
                      tickets={this.props.tickets}
                      venueID={this.props.selectedID}
                      loading={this.props.loading}
                      updateSupport={this.props.updateSupport}
                      coreEquipmentState={this.props.coreEquipmentState}
                      venueList={this.props.venueList}
                      getVenues={this.getVenues}
                    />
                  </MDBModal>
                </MDBNavItem>
                <MDBNavItem className="tenth-step">
                  <MDBNavLink to="#" onClick={this.togglePropertySettings}>
                    {t("navigation.settings")}
                  </MDBNavLink>
                  <MDBModal
                    isOpen={this.state.modalPropertySettings}
                    toggle={this.togglePropertySettings}
                    size="lg"
                    centered
                  >
                    <PropertySettingsNavigation
                      togglePropertySettings={this.togglePropertySettings}
                      venueNameSelected={this.props.selectedName}
                      venueListName={this.state.venueListName}
                      updateName={this.props.updateName}
                      venueData={this.props.venueData}
                      venueID={this.props.selectedID}
                      venueAdmins={this.props.venueAdmins}
                      leasingAgents={this.props.leasingAgents}
                      loading={this.props.loading}
                      buildings={this.props.buildings}
                      getVenues={this.getVenues}
                    />
                  </MDBModal>
                </MDBNavItem>
                <div className="account">
                  <div className="darkModeToggle">
                    <label>
                      <Toggle
                        className="colorMode"
                        defaultChecked={this.state.darkmode}
                        icons={{
                          unchecked: <MDBIcon icon="sun" />,
                          checked: <MDBIcon icon="moon" />,
                        }}
                        onChange={this.props.handleSwitchChange(1)}
                      />
                    </label>
                  </div>
                  <div className="accountLogout">
                    {showTranslation ? (
                      <LanguageSwitch
                        venueID={this.props.selectedID}
                        venueNameSelected={this.props.selectedName}
                        updateName={this.props.updateName}
                      />
                    ) : (
                      ""
                    )}
                    <MDBDropdown>
                      <MDBDropdownToggle
                        className="customClass eleventh-step twelveth-step"
                        nav
                      >
                        <MDBIcon icon="user-circle" />
                      </MDBDropdownToggle>
                      <MDBDropdownMenu right>
                        <MDBDropdownItem>
                          Welcome, {sessionStorage.getItem("firstName")}
                        </MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem onClick={this.toggleMyAccount}>
                          My Account
                        </MDBDropdownItem>
                        <MDBDropdownItem onClick={this.toggleResetPassword}>
                          Reset Password
                        </MDBDropdownItem>
                        <div className="twelveth-step">
                          {(sessionStorage.getItem("networkAdmin") > 0 ||
                            sessionStorage.getItem("customerSupport") > 0) &&
                          showNetworkOpAdmin === true ? (
                            <MDBDropdownItem>
                              <MDBNavLink
                                to="/networkdashboard"
                                className="accountSwitch"
                              >
                                {t("subnavigation.networkadmin")}
                              </MDBNavLink>
                            </MDBDropdownItem>
                          ) : (
                            ""
                          )}
                          <MDBDropdownItem>
                            <MDBNavLink
                              to="/myaccount"
                              className="accountSwitch"
                            >
                              Subscriber Account
                            </MDBNavLink>
                          </MDBDropdownItem>
                        </div>
                        {showTour === true ? (
                          <MDBDropdownItem onClick={this.props.closeTour}>
                            Show Tour
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                    <MDBNavItem>
                      <MDBNavLink
                        to={loginURL}
                        onClick={this.onSignOutClick}
                        className="logout"
                      >
                        {t("navigation.logout")}
                      </MDBNavLink>
                    </MDBNavItem>
                  </div>
                </div>
              </div>
            </MDBNavbarNav>
          </MDBNavbar>
        </>
      </>
    );
  }
}
export default withTranslation()(Navigation);
