import React, { Component } from "react";
import {
  MDBSpinner,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBBtn,
  toast,
  MDBProgress,
} from "mdbreact";
import VenueUnitSubscribersTable from "../../../components/NetworkMgmt/Venues/Datatables/VenueUnitSubscribersTableMUI";
import ModifyResident from "../../../components/VenueMgmt/Forms/ModifyResident/ModifyResident";
import { withTranslation } from "react-i18next";
import axios from "../../../axios";
import _ from "lodash";
import SelectSearch, { fuzzySearch } from "react-select-search";
class ViewVenueSubscribers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loading: false,
      unitSubscriptionsList: {},
      venueID: undefined,
      loading_upload: false,
      progress: 0,
      subIDList: []
    };
  }
  componentDidUpdate(prevProps) {
    if (this.state.venueID !== this.props.venueID) {
      this.getVenueDetails(this.props.venueID);
      this.setState({ venueID: this.props.venueID });
    }
  }
  toggleModifyResident = () => {
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
    });
  };
  toggleModifyService = () => {
    this.setState({
      modalModifyServiceInfo: !this.state.modalModifyServiceInfo,
    });
  };
  getVenueDetails = (index) => {
    this.setState({ loadingVenueDetails: true });
    if (index === undefined) {
      index = this.props.firstID;
    }
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: index,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    this.setState({
      unitSubscriptionsList: undefined,
    });
    axios
      .post("/networkoperatorconsole/venues/listVenueDetails", body, {
        headers: {},
      })
      .then((response) => {
        let sortedSubscriptionData = _(response.data[index]["Subscriptions"])
          .map(function (v, k) {
            // insert the key into the object
            return _.merge({}, v, { key: k });
          })
          .sortBy("StartDateTimeUTC.ISO8601")
          .reverse() // sort by name
          .value();
        this.setState({
          unitSubscriptionsList: sortedSubscriptionData,
        });
      })
      .catch((error) => {
        this.setState({ error: true });
        this.setState({ loadingVenueDetails: false });
      });
  };
  modifyResident = (key) => {
    this.setState({
      modalModifyResident: !this.state.modalModifyResident,
      recentResidents: this.props.recentResidents,
    });
    this.setState({
      SubscriberGivenName: key.SubscriberGivenName,
      SubscriberFamilyName: key.SubscriberFamilyName,
      SubscriberEmail: key.SubscriberEmail,
      SubscriberPhoneNumber: key["SubscriberPhoneNumber.E164"],
      contactpref_email: key.contactpref_email,
      contactpref_sms: key.contactpref_sms,
      SubscriberLocale: key.SubscriberLocale,
      UnitFloor: key.UnitFloor,
      UnitBuilding: key.UnitBuilding,
      SSID: key.ssid,
      StartDateTime: key["StartDateTimeUTC.ISO8601"],
      EndDateTime: key["EndDateTimeUTC.ISO8601"],
      UnitID: key.UnitID,
      UnitID_internal: key.UnitID_internal,
      servicePlanID: key.ServicePlan["ServicePlanID"],
      servicePlan: key.ServicePlan["ServicePlanName"],
      vlan: key.vlan,
      status: key.Status,
      realpageSyncDate: key["RealPageSyncDateTimeUTC.ISO8601"],
      subscriberManagedviaSSO: key["SubscriberManagedviaSSO"],
      paymentFailed: key["paymentFailed"],
      StripeClientSecret: key["StripeClientSecret"],
      country: key["Country.ISO3166A3"],
      subscriptionDiscount: key["VenueUnitShoppingCartPriceAdjustment"],
      venueDiscount: key["VenueShoppingCartPriceAdjustment"],
      externalSubscriberID: key["externalSubscriberID"],
      selectedServicePlanPrice: key["ListBillingCyclePrice"],
      stripeClientParentSecret: key["StripeClientSecret"],
      setupIntentID: key["SetupIntentId"],
    });
  };
  submitModify = (
    email,
    firstName,
    lastName,
    phoneNumber,
    language,
    contactpref_email,
    contactpref_sms,
    recentResidents,
    dateStart,
    oldDateStart,
    dateEnd,
    oldDateEnd,
    venueID,
    unitID,
    building,
    floor,
    servicePlanID,
    isUpdated,
    isUpdatedServicePlan,
    isUpdatedStartDate,
    isUpdatedEndDate,
    isUpdatedSubscriptionDiscount,
    unitID_internal,
    selectedServicePlanName,
    subscriberToken,
    updatedUnit,
    subscriptionDiscount,
    setupIntentID
  ) => {
    this.setState({ loading: true });
    let bodyDateStart = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart)
        .toISOString()
        .replace(/:00.\d+Z$/g, "Z"),
      "NewStartDateTimeUTC.ISO8601": new Date(dateStart)
        .toISOString()
        .replace(/:00.\d+Z$/g, "Z"),
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken,
    };
    let bodyDateEnd = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      "NewEndDateTimeUTC.ISO8601":
        dateEnd !== undefined
          ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
          : undefined,
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken,
    };
    let bodyServicePlan = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "CurrentStartDateTimeUTC.ISO8601": new Date(oldDateStart).toISOString(),
      NewServicePlanID: servicePlanID,
      UserTimeZone: new Date().getTimezoneOffset(),
      SubscriberToken: subscriberToken,
    };
    let bodyUpdatePII = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      GivenName: firstName,
      FamilyName: lastName,
      "PhoneNumber.E164": phoneNumber,
      locale: language,
      contactpref_email: true,
      contactpref_sms: contactpref_sms,
      VenueID: venueID,
      SubscriberToken: subscriberToken,
    };
    let bodyUpdateUnit = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      StripeClientSecret: sessionStorage.getItem(
        "stripeClientSecretSubscriber"
      ),
      SetupIntentId: setupIntentID,
      NewUnitID_internal: unitID_internal,
      VenueID: venueID,
    };
    let bodyUpdateSubscriberPrice = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      StripeClientSecret: sessionStorage.getItem(
        "stripeClientSecretSubscriber"
      ),
      SetupIntentId: setupIntentID,
      VenueID: venueID,
      NewVenueUnitPriceAdjustment: -Math.abs(subscriptionDiscount) * 100,
      "NewEndDateTimeUTC.ISO8601":
        dateEnd !== undefined
          ? new Date(dateEnd).toISOString().replace(/:00.\d+Z$/g, "Z")
          : "",
    };
    Promise.all([
      new Date(dateStart) > new Date() && isUpdatedStartDate === true
        ? axios.post(
            "/networkoperatorconsole/subscribers/resetSubscriptionStartDate",
            bodyDateStart,
            { headers: {} }
          )
        : console.log(
            "🚀 ~ file: ViewVenueSubscribers.js:206 ~ ViewVenueSubscribers ~ No Date Start Change, No Date Start Change"
          ),
      dateEnd !== undefined && isUpdatedEndDate === true
        ? axios.post(
            "/networkoperatorconsole/subscribers/resetSubscriptionEndDate",
            bodyDateEnd,
            { headers: {} }
          )
        : console.log(
            "🚀 ~ file: ViewVenueSubscribers.js:214 ~ ViewVenueSubscribers ~ bodyDateEnd",
            bodyDateEnd
          ),
      isUpdated === true
        ? axios.post(
            "/networkoperatorconsole/subscribers/updateSubscriberPII",
            bodyUpdatePII,
            { headers: {} }
          )
        : console.log(
            "🚀 ~ file: ViewVenueSubscribers.js:222 ~ ViewVenueSubscribers ~ No Change, No Change"
          ),
      isUpdatedServicePlan === true
        ? axios.post(
            "/networkoperatorconsole/subscribers/modifyServicePlan",
            bodyServicePlan,
            { headers: {} }
          )
        : console.log(
            "🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyServicePlan",
            bodyServicePlan
          ),
      updatedUnit === true
        ? axios.post(
            "/networkoperatorconsole/subscribers/modifyVenueUnit",
            bodyUpdateUnit,
            { headers: {} }
          )
        : console.log(
            "🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateUnit",
            bodyUpdateUnit
          ),
      isUpdatedSubscriptionDiscount === true
        ? axios.post(
            "/venuemgmtconsole/subscribers/modifySubscriptionPrice",
            bodyUpdateSubscriberPrice,
            { headers: {} }
          )
        : console.log(
            "🚀 ~ file: ViewVenueSubscribers.js:229 ~ ViewVenueSubscribers ~ bodyUpdateSubscriberPrice",
            bodyUpdateSubscriberPrice
          ),
    ])
      .then(async ([res1, res2, res3, res4, res5, res6]) => {
        toast.success("Updated Resident!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.setState({ activeData: undefined });
        this.getVenueDetails(venueID);
        if (isUpdatedServicePlan === true) {
          this.setState({
            loading: false,
            nextInvoiceAdjustedAmount:
              res4.data["NextInvoiceAdjustedAmount.USD"] === undefined
                ? ""
                : res4.data["NextInvoiceAdjustedAmount.USD"] / 100,
            nextInvoiceDate: res4.data["NextInvoiceDateTimeUTC.ISO8601"],
            billingCycleAdjustment:
              res4.data["BillingCyclePriceAdjustment"] === undefined
                ? ""
                : res4.data["BillingCyclePriceAdjustment"] / 100,
            subscriberName: firstName + " " + lastName,
            subscriberEmail: email,
            newServicePlanName: selectedServicePlanName,
            servicePlanID: servicePlanID,
            paymentFailed: res4.data["paymentFailed"],
          });
          sessionStorage.setItem("servicePlanIDSubscriber", servicePlanID);
          this.toggleModifyService();
        }
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.setState({ recentResidents: recentResidents });
        this.setState({ loading: false });
      });
  };
  deleteResident = (
    email,
    venueID,
    unitID,
    building,
    floor,
    recentResidents,
    oldDateStart,
    unitID_internal,
    cancellationReason,
    refundAmount
  ) => {
    this.setState({ loadingDelete: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      VenueID: venueID,
      UnitID_internal: unitID_internal,
      "StartDateTimeUTC.ISO8601": oldDateStart,
      "NewEndDateTimeUTC.ISO8601": oldDateStart,
      endImmediately: true,
      UserTimeZone: new Date().getTimezoneOffset(),
      CancellationReasons: cancellationReason,
    };
    axios
      .post("/venuemgmtconsole/subscribers/resetSubscriptionEndDate", body, {
        headers: {},
      })
      .then((response) => {
        toast.success("Ended Subscription!", {
          closeButton: false,
        });
        this.toggleModifyResident();
        this.getVenueDetails(this.props.venueID, this.props.venueName);
        this.setState({ loadingDelete: false });
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.setState({ error: true });
        this.setState({ loadingDelete: false });
      });
  };
  getSelectedSubscribers = (selectedRowsData) => {
    let subIDList = [];
    for (const key of selectedRowsData) {
      let subID = [];
      subID.push(key.subEmail, key.subUnitName);
      subIDList.push(subID);
    }
    this.setState({
      subIDList: subIDList,
    });
  };
  toggleConfirmSubscriberDeletion = (key) => {
    this.setState({
      modalConfirmSubscriberDeletion:
        !this.state.modalConfirmSubscriberDeletion,
    });
  };
  confirmDeleteSubscribers = async (data) => {
    this.setState({ loading_upload: true });
    if (this.state.confirmDelete === "REMOVE") {
      this.setState({
        isDeleting: true,
      });
      let i = 0;
      let subscriptionCount = this.state.subIDList.length;
      console.log(subscriptionCount);

      let deleteSubscribersAPI;
      let body;

      for (const key of this.state.subIDList) {
        deleteSubscribersAPI =
          "/networkoperatorconsole/subscribers/closeSubscriberAccount";
        body = {
          AccessToken: sessionStorage.getItem("AccessToken"),
          EmailAddress: key[0],
        };

        console.log(body);
        await axios
          .post(deleteSubscribersAPI, body, {
            headers: {},
          })
          .then(
            (response) => {
              toast.success("Subscription at " + key[0] + " Removed!", {
                closeButton: false,
              });

              if (subscriptionCount === i + 1) {
                this.toggleConfirmSubscriberDeletion();
                this.getVenueDetails(sessionStorage.getItem("venueID"));
                this.setState({
                  isDeleting: false,
                  loading_upload: false,
                });
              }
              console.log(i);
              this.setState({ progress: ((i + 1) / subscriptionCount) * 100 });
              i = i + 1;
            },
            (error) => {
              toast.error(
                "Error: Subscription at " + key[0] + " Was Not Removed!",
                {
                  closeButton: false,
                }
              );
              this.setState({
                isDeleting: false,
                loading_upload: false,
              });
              this.cancelUploadedFile();
            }
          );
      }
      console.log(deleteSubscribersAPI);
    } else {
      console.log("Please re-enter");
    }
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  cancelUploadedFile() {
    const randomString = Math.random().toString(36);
    this.setState({
      theInputKey: randomString,
    });
  }
  render() {
    return (
      <>
        <MDBModal
          size="lg"
          isOpen={this.state.modalConfirmSubscriberDeletion}
          toggle={this.toggleConfirmSubscriberDeletion}
        >
          <MDBModalHeader toggle={this.toggleConfirmSubscriberDeletion}>
            Confirm Subscriptions to be Removed
          </MDBModalHeader>
          <MDBModalBody>
            {this.state.loading_upload === true ? (
              <div
                className="overlay"
                id="overlay"
                style={{ display: "block" }}
              >
                <div className="overlayProgressBar">
                  <MDBProgress
                    material
                    value={this.state.progress}
                    height="20px"
                    color="danger"
                    striped
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="scrollableList">
            {this.state.subIDList !== undefined &&
            this.state.unitSubscriptionsList !== undefined ? (
              this.state.unitSubscriptionsList.length ===
              this.state.subIDList.length ? (
                <p>
                  <strong>All Subscribers Selected</strong>
                </p>
              ) : (
                this.state.subIDList.map((item, key) => {
                  return (
                    <p>
                      <b>Subscription:</b> {item[0]} - {item[1]} 
                    </p>
                  );
                })
              )
            ) : (
              ""
            )}
            </div>
            <p>
              READ THIS: Are you sure you want to end all of the selected
              subscriptions immediately? These users will immediately lose their
              Wi-Fi access, and new subscriptions will have to be created to
              restore it. THIS ACTION CANNOT BE UNDONE.
            </p>
            <input
              required
              name="confirmDelete"
              id="confirmDelete"
              className="form-control"
              onChange={this.changeHandler}
              value={this.state.address1}
              placeholder="Type REMOVE"
            />
          </MDBModalBody>
          <MDBModalFooter>
            <div
              className="text-right submitButton pt-2"
              key={this.state.theInputKey || ""}
            >
              <MDBBtn
                color="primary"
                onClick={this.confirmDeleteSubscribers}
                disabled={this.state.confirmDelete === "REMOVE" ? false : true}
              >
                {this.state.isDeleting === true ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Confirm Removal"
                )}
              </MDBBtn>
              <MDBBtn color="primary" onClick={this.toggleConfirmSubscriberDeletion}>
                Close
              </MDBBtn>
            </div>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalModifyServiceInfo}
          toggle={this.toggle}
          size="md"
          className="form"
          centered
        >
          <MDBModalHeader
            toggle={this.state.paymentFailed ? "" : this.toggleModifyService}
          >
            <h4>Modified Service Information</h4>
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBRow>
                <MDBCol sm="6" lg="4">
                  <p>Subscriber:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  {this.state.subscriberName}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol sm="6" lg="4">
                  <p>Email:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  {this.state.subscriberEmail}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol sm="6" lg="4">
                  <p>New Service Plan:</p>
                </MDBCol>
                <MDBCol sm="6" lg="8">
                  {this.state.newServicePlanName}
                </MDBCol>
              </MDBRow>
              {this.state.nextInvoiceDate !== undefined ? (
                <>
                  <MDBRow>
                    <MDBCol sm="6" lg="4">
                      <p>Next Invoice Date:</p>
                    </MDBCol>
                    <MDBCol sm="6" lg="8">
                      {this.state.nextInvoiceDate !== undefined
                        ? Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(new Date(this.state.nextInvoiceDate))
                        : ""}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol sm="6" lg="4">
                      <p>Next Invoice Amount:</p>
                    </MDBCol>
                    <MDBCol sm="6" lg="8">
                      ${this.state.nextInvoiceAdjustedAmount}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-end">
                    <MDBCol sm="6" lg="4">
                      <p>Billing Cycle Adjustment Amount:</p>
                    </MDBCol>
                    <MDBCol sm="6" lg="8">
                      ${this.state.billingCycleAdjustment}
                    </MDBCol>
                  </MDBRow>
                </>
              ) : (
                ""
              )}
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            {this.state.paymentFailed ? (
              <MDBBtn
                color="danger"
                className="btn Ripple-parent btn-default centered upgradePlan"
                href={
                  "/update/checkout?ui=" + window.location.pathname.slice(1)
                }
              >
                Modify Billing CC
              </MDBBtn>
            ) : (
              <MDBBtn
                color="danger"
                onClick={this.toggleModifyService}
                type="submit"
              >
                OK
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalModifyResident}
          toggle={this.toggle}
          size="fluid"
          className="form"
        >
          <ModifyResident
            toggleModifyResident={this.toggleModifyResident}
            firstName={this.state.SubscriberGivenName}
            lastName={this.state.SubscriberFamilyName}
            email={this.state.SubscriberEmail}
            phoneNumber={this.state.SubscriberPhoneNumber}
            receivetext={this.state.contactpref_email}
            receivephone={this.state.contactpref_sms}
            contactpref_sms={this.state.contactpref_sms}
            contactpref_email={this.state.contactpref_email}
            language={this.state.SubscriberLocale}
            floor={this.state.UnitFloor}
            building={this.state.UnitBuilding}
            startDate={this.state.StartDateTime}
            endDate={this.state.EndDateTime}
            unitID={this.state.UnitID}
            submitModify={this.submitModify}
            submitDelete={this.deleteResident}
            venueID={this.props.venueID}
            loading={this.state.loading}
            SSID={this.state.SSID}
            unitID_internal={this.state.UnitID_internal}
            servicePlanID={this.state.servicePlanID}
            servicePlan={this.state.ServicePlan}
            availablePlans={this.props.availablePlans}
            getVenueDetails={this.getVenueDetails}
            vlan={this.state.vlan}
            status={this.state.status}
            realpageSyncDate={this.state.realpageSyncDate}
            subscriberManagedviaSSO={this.state.subscriberManagedviaSSO}
            paymentFailed={this.state.paymentFailed}
            StripeClientSecret={this.state.StripeClientSecret}
            country={this.state.country}
            subscriptionDiscount={this.state.subscriptionDiscount}
            venueDiscount={this.state.venueDiscount}
            externalSubscriberID={this.state.externalSubscriberID}
            selectedServicePlanPrice={this.state.selectedServicePlanPrice}
            stripeClientParentSecret={this.state.stripeClientParentSecret}
            setupIntentID={this.state.setupIntentID}
            showUpdate={this.props.showUpdate}
            cancelInputType={this.props.cancelInputType}
          />
        </MDBModal>
        <MDBModalHeader toggle={this.props.toggleViewModifySubscriber}>
          <h4>Manage Venue Subscribers</h4>
        </MDBModalHeader>
        <MDBModalBody>
          <SelectSearch
            id="selectVenue"
            search={true}
            filterOptions={fuzzySearch}
            options={this.props.optionTemplateVenues}
            isOptionSelected={true}
            inputId="venueArray"
            classNamePrefix="venue-select"
            placeholder="Select..."
            onChange={this.props.handleSelectChangeVenue}
            value={this.props.clearDropDown === true ? "" : this.props.venueID}
          />
          {this.state.unitSubscriptionsList !== undefined ? (
            <>
              <div className="allrecentresidentsExpanded">
                <VenueUnitSubscribersTable
                  modifyResident={this.modifyResident}
                  unitSubscriptionsList={this.state.unitSubscriptionsList}
                  getSubscriptionList={this.props.getSubscriptionList}
                  refreshed={this.props.refreshed}
                  getSelectedSubscribers={this.getSelectedSubscribers}
                />
              </div>
            </>
          ) : (
            <MDBSpinner small />
          )}
          <div className="text-right submitButton pt-2">
            <MDBBtn
              color="danger"
              onClick={this.toggleConfirmSubscriberDeletion}
              disabled={this.state.subIDList.length === 0 ? true : false}
            >
              Remove Selected Subscriptions
            </MDBBtn>
          </div>
        </MDBModalBody>
      </>
    );
  }
}
export default withTranslation()(ViewVenueSubscribers);
