import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

  let datatable;
  [datatable] = React.useState("");
  if (props.transactions !== undefined) {
    userAttributes = [];
    for (const key of Object.keys(props.transactions)) {

      let Address2 = props.transactions[key]['AddressLine2']!==""?props.transactions[key]['AddressLine2']+"\n\r":""
      userAttributes.push({
        name: props.transactions[key]["GivenName"]+" "+props.transactions[key]["FamilyName"],
        email: props.transactions[key]["EmailAddress"],
        phoneNumber: props.transactions[key]["PhoneNumber.E164"],
        servicePlanName: props.transactions[key]["ServicePlanName"],
        venuePriceAdjustment: props.transactions[key]["CurrencySymbol"]+props.transactions[key]["VenuePriceAdjustment"]/100,
        trialCycles: props.transactions[key]["TrialCycles"],
        billingCyclePrice: props.transactions[key]["CurrencySymbol"]+props.transactions[key]["BillingCyclePrice.USD"]/100,
        venueAddress: props.transactions[key]["VenueName"]+"\n\r"+props.transactions[key]['AddressLine1']+"\n\r"+Address2+props.transactions[key]['City']+", "+props.transactions[key]['State']+" "+props.transactions[key]['PostalCode'],
        unitID: props.transactions[key]["UnitID_internal"],
        
          
      });
      i = i + 1;
    }
  }

  datatable = ({
    columns: [
      {
        label: "Name",
        field: "name",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "Phone Number",
        field: "phoneNumber",
      },
      {
        label: "Service Plan Name",
        field: "servicePlanName",
      },
      {
        label: "Venue Price Adjustment",
        field: "venuePriceAdjustment",
      },
      {
        label: "Trial Cycles",
        field: "trialCycles",
      },
      {
        label: "Billing Cycle Price",
        field: "billingCyclePrice",
      },
      {
        label: "Address",
        field: "venueAddress",
      },
      {
        label: "Unit ID",
        field: "unitID"
      }
    ],
    rows: userAttributes,
  });
  return (
    <MDBDataTableV5
      responsive
      hover
      data={datatable}
      searchBottom={false}
      paging={false}
      className="modifyStaff"
      info={false}
      noRecordsFoundLabel={t("recentservice.nomatches")}
      exportToCSV={props.transactions !== undefined?true:false}
    />
  );
}