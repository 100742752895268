/* Find Deals screen */
import React, { useState, useEffect, Suspense } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import axios from "../../../axios";
import { useHistory } from "react-router-dom";
export default function App() {
  let BrandName;
  let CheckOutComponent = React.lazy(() =>
    import(
      "../../../brands/" +
        sessionStorage.getItem("customBrand") +
        "/components/Upgrade/CheckOut"
    )
  );
  const [error] = useState("");
  const [state, setState] = useState({
    modalBillingDetails: false,
    paymentFailed: false,
    updateCC: false,
    loading: false,
  });
  const history = useHistory();
  useEffect(() => {
    setState({
      loading: true,
    });
    const data = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      NewServicePlanID: sessionStorage.getItem("servicePlanID"),
      "StartDateTimeUTC.ISO8601": new Date(
        sessionStorage.getItem("dateStart")
      ).toISOString(),
      UnitID_internal: sessionStorage.getItem("unitID_internalSubscriber"),
    };
    axios
      .post("/subscriberconsole/modifyServicePlan", data, {
        headers: {},
      })
      .then((response) => {
        setState({
          nextInvoiceAdjustedAmount:
            response.data["NextInvoiceAdjustedAmount.USD"] === undefined
              ? ""
              : response.data["NextInvoiceAdjustedAmount.USD"] / 100,
          nextInvoiceDate: response.data["NextInvoiceDateTimeUTC.ISO8601"],
          billingCycleAdjustment:
            response.data["BillingCyclePriceAdjustments"] === undefined ||
            response.data["BillingCyclePriceAdjustments"] === 0
              ? 0
              : response.data["BillingCyclePriceAdjustments"] / 100,
          paymentFailed: response.data["paymentFailed"],
          billingDetails: response.data,
          loading: false,
        });
        
      })
      .catch((error) => {
        console.log("🚀 ~ file: CheckOut.js:75 ~ useEffect ~ error", error)
      });
  }, []);
  return (
    <>
      <div className="CreateAccount">
        <MDBContainer className="Form" fluid>
          <MDBRow className="g-0 m-0">
            <MDBCol sm="12" md="12" lg="12" className="ml-auto p-0">
              {error ? (
                <>
                  <div className="topBanner">
                    <h2>There was an error</h2>
                  </div>
                  <div className="FormContainer">
                    <div className="loginDisclosure">
                      <div className="App">
                        <h2>{error}</h2>
                        <div className="returnButton">
                          <a className="btn Ripple-parent" href="/myaccount">
                            Return to Dashboard
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {state.loading === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                      <div class="overlayMessage">
                        <div
                          className="spinner-border spinner-border"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  ) : state.paymentFailed === true ? (
                    history.push("/update/checkout?ui=myaccount")
                  ) : (
                    <>
                      <Suspense
                        fallback={
                          <div
                            className="overlay"
                            id="overlay"
                            style={{ display: "block" }}
                          >
                            <div className="overlayMessage">
                              <div
                                className="spinner-border spinner-border"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <CheckOutComponent
                          nextInvoiceAdjustedAmount={
                            state.nextInvoiceAdjustedAmount
                          }
                          billingCycleAdjustment={state.billingCycleAdjustment}
                          BrandName={BrandName}
                        />
                      </Suspense>
                    </>
                  )}
                </>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </>
  );
}
