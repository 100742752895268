/* Find Deals screen */
import React, { useState, useEffect, Suspense } from "react";
import axios from "../../axios";
import Footer from "../../components/Common/Footer";
import { useHistory } from "react-router-dom";
let CheckOutComponent = React.lazy(() =>
  import(
    "../../brands/" +
      sessionStorage.getItem("customBrand") +
      "/components/CreateAccount/CheckOut"
  )
);
export default function App(props) {
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState("");
  const [state, setState] = useState({
    modalBillingDetails: false,
    errorMessage: undefined,
  });
  const history = useHistory();
  useEffect(() => {
    let BillingCycleRecurrence;
    !sessionStorage.getItem("daysofservice")
      ? (BillingCycleRecurrence = 0)
      : (BillingCycleRecurrence = sessionStorage.getItem("daysofservice"));
    let data;
    sessionStorage.getItem("unit") !== "undefined"
      ? (data = {
          VenueID: sessionStorage.getItem("venueID"),
          ServicePlanID: sessionStorage.getItem("servicePlanID"),
          UnitID_internal: sessionStorage.getItem("unit"),
          EmailAddress: sessionStorage.getItem("email"),
          GivenName: sessionStorage.getItem("firstName"),
          FamilyName: sessionStorage.getItem("lastName"),
          PhoneNumber: sessionStorage.getItem("phoneNumber"),
          locale: "en-us",
          contactpref_sms: sessionStorage.getItem("sms"),
          StartDateTimeUTC: new Date(
            sessionStorage.getItem("dateStart")
          ).toISOString(),
          EndDateTimeUTC: "",
          BillingCycleRecurrence: BillingCycleRecurrence,
        })
      : (data = {
          VenueID: sessionStorage.getItem("venueID"),
          ServicePlanID: sessionStorage.getItem("servicePlanID"),
          EmailAddress: sessionStorage.getItem("email"),
          GivenName: sessionStorage.getItem("firstName"),
          FamilyName: sessionStorage.getItem("lastName"),
          PhoneNumber: sessionStorage.getItem("phoneNumber"),
          locale: "en-us",
          contactpref_sms: sessionStorage.getItem("sms"),
          StartDateTimeUTC: new Date(
            sessionStorage.getItem("dateStart")
          ).toISOString(),
          EndDateTimeUTC: "",
          BillingCycleRecurrence: BillingCycleRecurrence,
        });
        axios
        .post("/networkoperatorconsole/shoppingcart/requestSubscription", data, {
            headers: {},
        })
        .then((response) => {
            if(response.data.getSMSOTP){
              history.push("/verification");
            }
            else if(response.data.StripeClientSecret === undefined){
              history.push("/paymentsuccess");
            }else{
              setClientSecret(response.data.StripeClientSecret);
              sessionStorage.setItem("clientSecret", response.data.StripeClientSecret);
              sessionStorage.setItem("nextPaymentDate", response.data.NextPaymentDate);
              sessionStorage.setItem("proratedPricePerHour", response.data["ProratedPricePerHour.USD"]);
              sessionStorage.setItem("unbilledHoursTilNextPaymentDate", response.data.UnbilledHoursTillNextPaymentDate);
              sessionStorage.setItem("trialEndDate", response.data.TrialEndDate);
              sessionStorage.setItem("firstInvoiceDate", response.data["FirstInvoiceDateUTC.ISO8601"]);
              setStripePromise(response.data.StripePublishableKey);
              setState({billingDetails: response.data});
              sessionStorage.setItem("getSMSOTP", response.data.getSMSOTP);
            }
            
        })
        .catch((error) => {
            setState({errorMessage: error.response.data.message});
        });

}, [history]);
  function toggleBillingDetails() {
    setState({
      modalBillingDetails: !state.modalBillingDetails,
    });
  }
  return (
    <>
      <Suspense
        fallback={
          <div className="overlay" id="overlay" style={{ display: "block" }}>
            <div className="overlayMessage">
              {}
              <div className="spinner-border spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        }
      >
        <CheckOutComponent
          stripePromise={stripePromise}
          clientSecret={clientSecret}
          logo={props.logoLogin}
          brand={sessionStorage.getItem("brand")}
          toggleBillingDetails={toggleBillingDetails}
          modalBillingDetails={state.modalBillingDetails}
          billingDetails={state.billingDetails}
          errorMessage={state.errorMessage}
        />
        <Footer />
      </Suspense>
    </>
  );
}
