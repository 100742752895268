import { Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody } from "mdbreact";
import TandC from "../../components/CreateAccount/TandC";
let BrandName = sessionStorage.getItem("clientName");
let PhoneNumber = sessionStorage.getItem("supportPhone");
let PrivacyPolicy;
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tandc: false,
      modalTandC: false,
      loadingTandC: false,
    };
  }
  formatPhoneNumber(phoneNumberString) {
   var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
   if (match) {
     var intlCode = (match[1] ? '' : '');
     return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
   }
   return null;
  }
  toggleTandC = () => {
    this.setState({
      modalTandC: !this.state.modalTandC,
    });
  };
  render() {
    return (
      <>
        <MDBModal
          isOpen={this.state.modalTandC}
          size="lg"
          className="form"
          centered
          overflowScroll
        >
          <MDBModalHeader toggle={this.toggleTandC}>
            {BrandName} Terms and Conditions
          </MDBModalHeader>
          <MDBModalBody className="faqModal" id="faq">
            <TandC />
            <button
              className="btn Ripple-parent btn-danger nextButton"
              onClick={this.toggleTandC}
            >
              Close
            </button>
          </MDBModalBody>
        </MDBModal>
        <div className="footerCreateAccount">
          <p>
            {BrandName} Support {this.formatPhoneNumber(PhoneNumber)}
          </p>
          <p className="text-piped">
            <span className="piped">
              &copy; Copyright 2012 - {new Date().getFullYear()}{" "}
              {BrandName}
            </span>
            <span className="piped">All Rights Reserved</span>
            <span className="piped">
              <button onClick={this.toggleTandC}>Terms of Service </button>
            </span>
            {PrivacyPolicy !== "#" ?
              <span className="piped">
                <a href={sessionStorage.getItem("PrivacyPolicyURL")} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </span>
              : ""}
          </p>
        </div>
      </>
    );
  }
}
export default Footer;