import React, { Component } from "react";

import { MDBBtn, MDBSpinner, MDBModal, toast } from "mdbreact";

import VenueEquipmentTable from "../../../components/NetworkMgmt/Venues/Datatables/VenueEquipmentTable";

import AddVenueEquipment from "../../../components/NetworkMgmt/Forms/AddVenueEquipment/AddVenueEquipment";

import ModifyEquipment from "../../../components/NetworkMgmt/Forms/ModifyEquipment/ModifyEquipment";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";




class ViewVenueEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loading: false,
			disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false
    };
  }

  toggleAddVenueEquipment = () => {
    this.setState({
      modalAddVenueEquipment: !this.state.modalAddVenueEquipment,
    });
  };

  submitAddVenueEquipment = (equipID, manufacturer, model, type, zone, protocol, ipaddy, port, userName, userPassword, status, pre643) => {
    this.setState({ add_venueequip: true });

    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: this.props.venueID,
      EquipmentID: equipID,
      Manufacturer: manufacturer,
      Model: model,
      EquipmentType: type,
      WirelessZone: zone,
      ConnectionProtocol: protocol,
      IPAddress: ipaddy,
      Port: port,
      Username: userName,
      Password: userPassword,
      Status: status,
      MikrotikPre643: pre643,
    };

    axios
      .post("/networkoperatorconsole/venues/addVenueEquipment", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Added Equipment to Venue!", {
            closeButton: false,
          });
          this.toggleAddVenueEquipment();
          this.props.updateName(this.props.venueName, this.props.venueID);
          this.props.getVenueDetails(this.props.venueID);
          this.setState({ add_venueequip: false });
        },
        (error) => {
          toast.error(error.response.data.message, {
            closeButton: false,
          });
          // this.toggleAddVenueEquipment();
          this.setState({ add_venueequip: false });
        }
      );
  };

  toggleModifyEquipment = () => {
    this.setState({
      modalModifyEquipment: !this.state.modalModifyEquipment,
    });
  };

  modifyEquipment = (key) => {
    this.setState({
      modalModifyEquipment: !this.state.modalModifyEquipment,
      equipID: key,
    });
  };

  submitModifyEquipment = (equipName, manufacturer, model, type, zone, protocol, ipaddy, port, userName, userPassword, status, pre643) => {
    this.setState({ modify_equipment: true });

    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      SharedSecret: this.props.sharedSecret,
      VenueID: this.props.venueID,
      EquipmentID: this.state.equipID,
      Manufacturer: manufacturer,
      Model: model,
      EquipmentType: type,
      WirelessZone: zone,
      ConnectionProtocol: protocol,
      IPAddress: ipaddy,
      Port: port,
      Username: userName,
      Password: userPassword,
      Status: status,
      MikrotikPre643: pre643,
    };
    axios
      .post("/networkoperatorconsole/venues/updateVenueEquipment", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Updated Equipment!", {
            closeButton: false,
          });
          this.toggleModifyEquipment();
          this.props.updateName(this.props.venueName, this.props.venueID);
          this.props.getVenueDetails(this.props.venueID);
          this.setState({ modify_equipment: false });
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.toggleModifyEquipment();
          this.setState({ modify_equipment: false });
        }
      );
  };

  render() {

    return (
      <>
        <MDBModal isOpen={this.state.modalModifyEquipment} toggle={this.toggle} size="lg" className="form" centered>
          <ModifyEquipment
            toggleModifyEquipment={this.toggleModifyEquipment}
            equipID={this.state.equipID}
            venueName={this.props.venueName}
            submitModifyEquipment={this.submitModifyEquipment}
            modify_equipment={this.state.modify_equipment}
            venueEquipList={this.props.venueEquipList}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalAddVenueEquipment} toggle={this.toggle} size="lg" className="form" centered>
          <AddVenueEquipment
            toggleAddVenueEquipment={this.toggleAddVenueEquipment}
            venueName={this.props.venueName}
            submitAddVenueEquipment={this.submitAddVenueEquipment}
            add_venueequip={this.state.add_venueequip}
          />
        </MDBModal>
        {this.props.venueEquipList !== undefined ? (
          <>
            <div className="servicePlans">
              <VenueEquipmentTable venueEquipList={this.props.venueEquipList} refreshed={this.props.refreshed} modifyEquipment={this.modifyEquipment} />
            </div>
          </>
        ) : (
          <MDBSpinner small />
        )}
        <MDBBtn 
          color="danger" 
          onClick={this.toggleAddVenueEquipment} 
          className="btn-right"
          disabled={this.state.disabled}
        >
          Add Equipment
        </MDBBtn>
      </>
    );
  }
}

export default withTranslation()(ViewVenueEquipment);