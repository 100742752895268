import React, { Component } from "react";

import { MDBSpinner, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, toast } from "mdbreact";

import VenueUnitsTable from "../../../components/NetworkMgmt/Venues/Datatables/VenueUnitsTable";

import ModifyUnit from "../../../components/NetworkMgmt/Forms/ModifyUnit/ModifyUnit";

import ViewVenueUnitSubscriptions from "../../../components/NetworkMgmt/Venues/ViewVenueUnitSubscriptions";

import { withTranslation } from "react-i18next";

import axios from "../../../axios";

import DownloadUnits from "../../../components/NetworkMgmt/Forms/BulkDownloadUnits/BulkDownloadUnits";

import UploadUnits from "../../../components/NetworkMgmt/Forms/BulkUploadUnits/BulkUploadUnits";

import AddUnit from "../../../components/NetworkMgmt/Forms/AddUnit/AddUnit";

class ViewVenueUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loading: false,
      update_unit: false,
      isDeleting: false,
			disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false,
   attributeForm: this.props.attributeForm
    };
  }

  toggleViewUnitSubscriptions = () => {
    let isOpen = !this.state.modalViewUnitSubscriptions;
    this.setState({
      modalViewUnitSubscriptions: !this.state.modalViewUnitSubscriptions,
    });
    if (isOpen === false) {
      this.props.getVenueDetails(sessionStorage.getItem("venueID"));
    }
  };

  toggleViewModifyUnit = () => {
    let isOpen = !this.state.modalViewModifyUnit;
    this.setState({
      modalViewModifyUnit: !this.state.modalViewModifyUnit,
    });
    if (isOpen === false) {
      this.props.getVenueDetails(sessionStorage.getItem("venueID"));
    }
  };

  toggleDownloadUnits = () => {
   this.setState({
     modalDownloadUnits: !this.state.modalDownloadUnits,
   });
  };

  toggleUploadUnits = () => {
   this.setState({
     modalUploadUnits: !this.state.modalUploadUnits,
     selectedID: undefined
   });
  };

  toggleAddUnit = () => {
   this.setState({
     modalAddUnit: !this.state.modalAddUnit,
   });
  };

  getSubscriptionList = (key) => {
    this.setState({
      unitSubscriptionsList: this.props.unitsList[key.id]["subscriptions"],
      unitInfo: this.props.unitsList[key.id],
      unitSubscriptionsListID: Object.keys(this.props.unitsList[key.id]["subscriptions"])
    });
    this.toggleViewModifyUnit();
  };

  getSelectedUnits = (selectedRowsData) => {
    // console.log(selectedRowsData)

    let unitIDList = [];
    for (const key of selectedRowsData) {
      let unitID = [];
      unitID.push(key.unitID_internal, key.unitID, key.building, key.floor)
      unitIDList.push(unitID)
    }
    this.setState({
      unitIDList: unitIDList
    });
  }

  toggleConfirmUnitDeletion = (key) => {
    this.setState({
      modalConfirmUnitDeletion: !this.state.modalConfirmUnitDeletion,
    });
  };

  confirmDeleteUnits = () => {
    if (this.state.confirmDelete === "DELETE") {
      this.setState({
        isDeleting : true
      })
      let i = 0;
      let unitCount = this.state.unitIDList.length;
      console.log("Deleted");

      let deleteUnitsAPI;
      let body;
      if(this.props.unitsList.length === unitCount){
        deleteUnitsAPI = "/networkoperatorconsole/admin/deleteAllVenueUnitsPermanently"
        let body = {
          AccessToken: sessionStorage.getItem("AccessToken"),
          VenueID: sessionStorage.getItem("venueID"),
        };
        axios
      .post(deleteUnitsAPI, body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Units Deleted!", {
            closeButton: false,
          });
            this.toggleConfirmUnitDeletion();
            this.props.getVenueDetails(sessionStorage.getItem("venueID"));
            this.setState({
              isDeleting: false
            })
        },
        (error) => {
          toast.error(error.response.data.message, {
            closeButton: false,
          });
          this.setState({
            isDeleting: false
          })
          this.cancelUploadedFile();
        }
      );
      }else{
      for (const key of this.state.unitIDList) {
      
        deleteUnitsAPI = "/networkoperatorconsole/admin/deleteVenueUnitPermanently";
        body = {
          AccessToken: sessionStorage.getItem("AccessToken"),
          UnitID_internal: key[0],
        };
      
      console.log(body)
      axios
      .post(deleteUnitsAPI, body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Unit "+key[1]+" Deleted!", {
            closeButton: false,
          });
          i = i + 1;
          if(unitCount === i){
            this.toggleConfirmUnitDeletion();
            this.props.getVenueDetails(sessionStorage.getItem("venueID"));
            this.setState({
              isDeleting: false
            })
          }
        },
        (error) => {
          toast.error( "Error: Unit "+key[1]+" Was Not Deleted!", {
            closeButton: false,
          });
          this.setState({
            isDeleting: false
          })
          this.cancelUploadedFile();
        }
      );
      }
    }
    console.log(deleteUnitsAPI)
    } else {
      console.log("Please re-enter")
    }
  }

  cancelUploadedFile() {
    const randomString = Math.random().toString(36);
    this.setState({
        theInputKey: randomString,
    });
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitModifyUnitAddress = (venueID, unitID, building, floor, address1, address2, city, state, country, postalcode, smsCode, unitID_internal, vlan, shoppingCartUnitPriceAdjustment, allowOverlapping, enableShoppingCart, selectedServicePlan) => {
    this.setState({ update_unit: true });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      UnitID_internal: unitID_internal,
      SMSCode: smsCode,
      AddressLine1: address1,
      AddressLine2: address2,
      City: city,
      State: state,
      "Country.ISO3166A3": country,
      PostalCode: postalcode,
      vlan: vlan,
      ShoppingCartPriceAdjustment: -Math.abs(shoppingCartUnitPriceAdjustment) * 100,
      allowOverlappingSubscriptionWiredUnit: allowOverlapping,
			enableShoppingCart: enableShoppingCart,
   ServicePlanID: selectedServicePlan,
    };
    axios
      .post("/networkoperatorconsole/venues/updateVenueUnitAddress", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Updated Unit Information!", {
            closeButton: false,
          });
          this.toggleViewModifyUnit();
          this.props.getVenueDetails(sessionStorage.getItem("venueID"));
          this.setState({ update_unit: false });
        },
        (error) => {
          toast.error(error.response.data.message, {
            closeButton: false,
          });
          this.toggleViewModifyUnit();
          this.setState({ update_unit: false });
        }
      );
  };

  addUnit = (venueID, unitName, unitBuilding, unitFloor, address1, address2, country, city, region, zipcode, purpose, vlan, selectedPlan, smsCode) => {
   if (address2 === undefined) {
     address2 = "";
   }
   this.setState({ loadingUnit: true });
   this.setState({ reloadVenueList: true });
   const data = {
     VenueID: venueID,
     UnitID: unitName,
     UnitFloor: unitFloor,
     UnitBuilding: unitBuilding,
     AddressLine1: address1,
     AddressLine2: address2,
     City: city,
     State: region,
     PostalCode: zipcode,
     "Country.ISO3166A3": country,
     AccessToken: sessionStorage.getItem("AccessToken"),
     vlan: vlan,
     purpose: purpose,
     ServicePlanID: selectedPlan,
     SMSCode: smsCode
   };
   axios
     .post("/networkoperatorconsole/venues/addVenueUnits", data, {
       headers: {},
     })

     .then(
       (response) => {
         this.props.updateName(sessionStorage.getItem("venueName"), venueID);
         toast.success("Added Unit to Venue!", {
           closeButton: false,
         });
         this.setState({ loadingUnit: false });
         this.toggleAddUnit();
         this.setState({ reloadVenueList: false });
       },
       (error) => {
         toast.error("There was an error", {
           closeButton: false,
         });
         this.setState({ loadingUnit: false });
         this.setState({ reloadVenueList: false });
       }
     );
  };

  render() {
    return (
      <>
      {
				this.state.isDeleting === true ? (
					<div
						className="overlay"
						id="overlay"
						style={{ display: "block" }}
					>
						<div className="overlayMessage">
							<div
								className="spinner-border spinner-border"
								role="status"
							>
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</div>
				) : "" }
        <MDBModal
          isOpen={this.state.modalConfirmUnitDeletion}
          toggle={this.toggleConfirmUnitDeletion}
        >
          <MDBModalHeader toggle={this.toggleConfirmUnitDeletion}>
            Confirm Units to be Deleted
          </MDBModalHeader>
          <MDBModalBody>
            {
              this.state.unitIDList !== undefined && this.props.unitsList !== undefined ?
              this.props.unitsList.length === this.state.unitIDList.length ? 
                    <p><strong>All Units Selected</strong></p> :
                this.state.unitIDList.map((item, key) => {
                  return (
                    
                    <p>Unit ID: {item[1]}, Building: {item[2]}, Floor: {item[3]}</p>
                  )

                })
                : ""
            }
            <p>
              “WARNING: THIS ACTION CANNOT BE UNDONE. This will delete the selected units from the venue. Any units with an active subscription will not be deleted. To confirm, please type “DELETE” into the text box below
            </p>
            <input
              required
              name="confirmDelete"
              id="confirmDelete"
              className="form-control"
              onChange={
                this.changeHandler
              }
              value={this.state.address1}
              placeholder="Type DELETE"
            />
          </MDBModalBody>
          <MDBModalFooter>
          <div className="text-right submitButton pt-2" key={this.state.theInputKey || ""}>
            <MDBBtn 
              color="primary" 
              onClick={this.confirmDeleteUnits}
              disabled={this.state.confirmDelete === "DELETE" ? false : true}
            >
              {this.state.isDeleting === true ? (
											<div className="spinner-border spinner-border-sm" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										) : (
											"Confirm"
										)}
            </MDBBtn>
            <MDBBtn
              color="primary"
              onClick={this.toggleConfirmUnitDeletion}
            >
              Close
            </MDBBtn>
            </div>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.modalViewUnitSubscriptions} toggle={this.toggleViewUnitSubscriptions} size="fluid" className="form" centered>
          <ViewVenueUnitSubscriptions
            unitSubscriptionsList={this.state.unitSubscriptionsList}
            toggleViewUnitSubscriptions={this.toggleViewUnitSubscriptions}
            venueID={this.props.venueID}
            getVenueDetails={this.props.getVenueDetails}
            unitsList={this.props.unitsList}
            showUpdate={this.props.showUpdate}
            cancelInputType={this.props.cancelInputType}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalViewModifyUnit} toggle={this.toggleViewModifyUnit} size="fluid" className="form" centered>
          <ModifyUnit
            unitSubscriptionsList={this.state.unitSubscriptionsList}
            toggleViewModifyUnit={this.toggleViewModifyUnit}
            venueID={this.props.venueID}
            getVenueDetails={this.props.getVenueDetails}
            unitsList={this.props.unitsList}
            unitInfo={this.state.unitInfo}
            submitModifyUnitAddress={this.submitModifyUnitAddress}
            update_unit={this.state.update_unit}
            unitSubscriptionsListID={this.state.unitSubscriptionsListID}
            optionTemplateServicePlans={this.props.optionTemplateServicePlans}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalDownloadUnits} toggle={this.toggle} size="md" className="form" centered>
          <DownloadUnits
            toggleDownloadUnits={this.toggleDownloadUnits}
            venueID={this.props.venueID}
            unitsArrayAll={this.props.unitsArrayAll}
            venueName={this.props.venueName}
            updateName={this.props.updateName}
            venueType={this.props.venueType}
            UnitsBuildingFloorListSusbcribers={this.props.UnitsBuildingFloorListSusbcribers}
            attributeForm={this.state.attributeForm}
            loadingVenueDetails={this.props.loadingVenueDetails}
            hasUpdated={this.props.hasUpdated}
            optionTemplateVenues={this.props.optionTemplateVenues}
            handleSelectVenue={this.props.handleSelectVenue}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalUploadUnits} toggle={this.toggle} size="md" className="form" centered>
          <UploadUnits
            toggleUploadUnits={this.toggleUploadUnits}
            venueID={this.props.venueID}
            unitsArrayAll={this.props.unitsArrayAll}
            venueName={this.props.venueName}
            updateName={this.props.updateName}
            sharedSecret={this.state.sharedSecret}
            hasUpdated={this.props.hasUpdated}
            optionTemplateVenues={this.props.optionTemplateVenues}
            handleSelectVenue={this.props.handleSelectVenue}
            selectedID={this.state.selectedID}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalAddUnit} toggle={this.toggle} size="lg" className="form" centered>
          <AddUnit
            toggleAddUnit={this.toggleAddUnit}
            venueID={this.props.venueID}
            venueName={sessionStorage.getItem("venueName")}
            sharedSecret={this.state.sharedSecret}
            submitAddUnit={this.addUnit}
            loadingUnit={this.state.loadingUnit}
            attributeForm={this.state.attributeForm}
            hasUpdated={this.state.hasUpdated}
            selectedID={this.state.selectedID}
            address1={this.props.address1}
            address2={this.props.address2}
            city={this.props.city}
            region={this.props.state}
            country={this.props.country}
            postalcode={this.props.postalcode}
            optionTemplatePlans={this.state.optionTemplatePlans}
            optionTemplateVenues={this.props.optionTemplateVenues}
            optionTemplateServicePlans={this.props.optionTemplateServicePlans}
            handleSelectVenue={this.props.handleSelectVenue}
          />
        </MDBModal>
        {this.props.unitsList !== undefined ? (
          <>
            <VenueUnitsTable
              unitsList={this.props.unitsList}
              refreshed={this.props.refreshed}
              toggleViewUnitSubscriptions={this.toggleViewUnitSubscriptions}
              getSubscriptionList={this.getSubscriptionList}
              getSelectedUnits={this.getSelectedUnits}
            />
            <div className="text-right submitButton pt-2">
            <MDBBtn 
              color="danger" 
              onClick={this.toggleAddUnit}
              disabled={this.state.disabled}
            >
              Add Unit
            </MDBBtn>
            <MDBBtn 
              color="danger" 
              onClick={this.toggleDownloadUnits}
              disabled={this.state.disabled}
            >
              Download Units
            </MDBBtn>
            <MDBBtn 
              color="danger" 
              onClick={this.toggleUploadUnits}
              disabled={this.state.disabled}
            >
              Upload Units
            </MDBBtn>
            <MDBBtn 
              color="danger" 
              onClick={this.toggleConfirmUnitDeletion}
              disabled={this.state.disabled}
            >
              Delete Selected Units
            </MDBBtn>
            </div>
            
          </>
        ) : (
          <MDBSpinner small />
        )}
      </>
    );
  }
}

export default withTranslation()(ViewVenueUnits);