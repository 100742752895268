import React, { Component } from "react";
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBRow,
	MDBCol,
	toast,
	ToastContainer,
} from "mdbreact";
import axios from "../../../axios";
import "react-phone-number-input/style.css";
import PhoneInput, {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
} from "react-phone-number-input";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import SelectSearch, { fuzzySearch } from "react-select-search";
class MyAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: this.props.allData["EmailAddress"],
			firstName: this.props.allData["GivenName"],
			lastName: this.props.allData["FamilyName"],
			phoneNumber: this.props.allData["PhoneNumber.E164"],
			modalAddedTicket: false,
			loading1: false,
			invalidEmail: null,
			typeOptions: [
				{ value: "TV", name: "TV" },
				{ value: "Internet", name: "Internet" },
				{ value: "Account", name: "Account" },
			],
		};
		this.handleEditorChange = this.handleEditorChange.bind(this);
	}
	componentDidMount() {
		this.validateEmail(this.state.email);
	}
	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	changeHandlerPhone = (event) => {
		this.setState({ phoneNumber: event });
		var value = event
			? isPossiblePhoneNumber(event)
				? undefined
				: "Please provide a valid phone number"
			: "Phone number required";
		this.setState({ invalidPhone: value });
	};
	validateEmail = (email) => {
		var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
		if (!email.match(mailformat)) {
			this.setState({
				invalidEmail: "Please provide a valid email address",
			});
			$(".emailInput").addClass("is-invalid");
		} else {
			this.setState({ invalidEmail: undefined });
			$(".emailInput").removeClass("is-invalid");
		}
	};
	toggleCheckboxValueConfirmations = () => {
		this.setState({ confirmations: !this.state.confirmations });
	};
	toggleCheckboxValueUpdates = () => {
		this.setState({ updates: !this.state.updates });
	};
	toggleCheckboxValueAgree = () => {
		this.setState({ agree: !this.state.agree });
	};
	handleEditorChange(description, editor) {
		this.setState({ description });
	}
	handleSelectChangeType = (value) => {
		if (value !== null) {
			this.setState({ type: value });
		}
	};
	toggleAddedTicket = () => {
		this.setState({
			modalAddedTicket: !this.state.modalAddedTicket,
		});
	};
	submitHandler = async () => {
		let firstKeyID;
		for (
			let i = 0;
			i < Object.keys(this.props.keys[0][this.props.venueName[0]]).length;
			i++
		) {
			if (
				Object.keys(this.props.keys[0][this.props.venueName[0]])[i] !==
				"DisplayOrder"
			) {
				if (
					Object.keys(this.props.keys[0][this.props.venueName[0]])[
						i
					] !== "ssid"
				) {
					firstKeyID = Object.keys(
						this.props.keys[0][this.props.venueName[0]]
					)[i];
					break;
				}
			}
		}
		let firstPolicyID = Object.keys(
			this.props.keys[0][this.props.venueName[0]][firstKeyID][
				"VenueUnitPolicies"
			]
		);
		let venueID =
			this.props.allData["KeysInKeyPools"][this.props.venueName[0]][
				firstKeyID
			]["VenueUnitPolicies"][firstPolicyID[0]]["VenueID"];
		let unitID =
			this.props.allData["KeysInKeyPools"][this.props.venueName[0]][
				firstKeyID
			]["VenueUnitPolicies"][firstPolicyID[0]]["UnitID_internal"];
		if (this.state.invalidEmail === undefined) {
			this.setState({ loading1: true });
			let body = {
				AccessToken: sessionStorage.getItem("AccessToken"),
				CustomUnit: undefined,
				Description: this.state.description,
				PrefContactMethod: undefined,
				TicketSubType: this.state.type,
				TicketType: "Resident Support",
				VAULTID: venueID,
				VAULTUnitID: unitID,
				contactEmailAddress: this.state.email,
				contactName: this.state.firstName + " " + this.state.lastName,
				contactPhoneNumber: this.state.phoneNumber,
				serviceboardid: undefined,
				summary: this.state.summary,
				unitID: undefined,
			};
			await axios
				.post("/subscriberconsole/addTicket", body, {
					headers: {},
				})
				.then(
					(response) => {
						this.setState({ loading1: false });
						this.setState({
							ticketID: response.data.id,
						});
						this.toggleAddedTicket();
						this.props.getSupportDetails();
					},
					(error) => {
						this.setState({ loading1: false });
						toast.error("There was an error!", {
							closeButton: false,
						});
					}
				);
		}
	};
	render() {
				const FORMAT = "MM/dd/yyyy";
		const { t } = this.props;
		return (
			<>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={10000}
				/>
				<MDBModal
					isOpen={this.state.modalAddedTicket}
					toggle={this.toggleAddedTicket}
					size="sm"
					className="smallModal"
					centered
				>
					<MDBModalHeader toggle={this.toggleAddedTicket}>
						<h4>{t("addticket.confirmationtitle")}</h4>
					</MDBModalHeader>
					<MDBModalBody>
						<div>
							<p>{t("addticket.thankyou")}</p>
							<p>
								{t("addticket.ticket")} #{this.state.ticketID}{" "}
								{t("addticket.hasbeensubmitted")}.
							</p>
						</div>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color="danger"
							onClick={this.props.toggleSupport}
						>
							Ok
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				<MDBModalHeader toggle={this.props.toggleSupport}>
					<h4>{t("addticket.title")}</h4>
				</MDBModalHeader>
				<MDBModalBody>
					<form className="needs-validation" noValidate>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addticket.selecttitle")}
								</label>
								<SelectSearch
									value={this.state.type}
									id="selectUnit"
									search={true}
									filterOptions={fuzzySearch}
									options={this.state.typeOptions}
									inputId="groupArray"
									classNamePrefix="unit-select"
									onChange={this.handleSelectChangeType}
									placeholder={"Select"}
								/>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol col="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addticket.summary")}
								</label>
								<input
									required
									id="profile"
									className="form-control"
									onChange={this.changeHandler}
									name="summary"
									value={this.state.summary}
								/>
								<div className="invalid-feedback">
									Please provide a valid profile name.
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol col="12">
								<label htmlFor="exampleFormControlTextarea1">
									{t("addticket.description")}
								</label>
								<textarea
									className="form-control"
									id="description"
									name="description"
									rows="10"
									onChange={this.changeHandler}
									value={this.state.description}>
								</textarea>	
								{/* <Editor
									apiKey="gw4td27mfhpoc1zr4q0yxusw050fnnxz00us4j5645mz285d"
									value={this.state.description}
									outputFormat="html"
									init={{
										height: 300,
										menubar: true,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste code help wordcount",
										],
										toolbar:
											"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | code | image |inserttable | cell row column | tableprops deletetable ",
									}}
									onEditorChange={this.handleEditorChange}
								/> */}
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<MDBRow>
									<MDBCol lg="4" middle>
										<p>{t("addticket.email")}</p>
									</MDBCol>
									<MDBCol lg="8">
										<input
											required
											id="email"
											type="email"
											className="form-control emailInput"
											onBlur={(event) =>
												this.validateEmail(
													this.state.email
												)
											}
											onChange={(event) =>
												this.setState({
													email: event.target.value,
												})
											}
											value={this.state.email}
											error={
												this.state.invalidEmail !==
												undefined
													? "Invalid email address"
													: "Email address required"
											}
										/>
										{this.state.invalidEmail !==
										undefined ? (
											<div className="invalid">
												{this.state.invalidEmail}
											</div>
										) : (
											""
										)}
									</MDBCol>
								</MDBRow>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<MDBRow>
									<MDBCol lg="4" middle>
										<p>{t("addticket.phone")}</p>
									</MDBCol>
									<MDBCol lg="8">
										<PhoneInput
											value={this.state.phoneNumber}
											format={FORMAT}
											onChange={this.changeHandlerPhone}
											name="phoneNumber"
											id="phoneNumber"
											className={
												this.state.loading === true
													? "form-control loading"
													: "form-control"
											}
											defaultCountry="US"
											required
											error={
												this.state.phoneNumber
													? isValidPhoneNumber(
															this.state
																.phoneNumber
													  )
														? undefined
														: "Invalid phone number"
													: "Phone number required"
											}
											disabled={this.state.userFound}
										/>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
						<MDBRow className="mb-2">
							<MDBCol sm="12" lg="6">
								<MDBRow>
									<MDBCol lg="4" middle>
										<p>{t("addticket.firstname")}</p>
									</MDBCol>
									<MDBCol lg="8">
										<input
											required
											id="profile"
											className="form-control"
											onChange={this.changeHandler}
											name="firstName"
											value={this.state.firstName}
										/>
										<div className="invalid-feedback">
											Please provide a valid profile name.
										</div>
									</MDBCol>
								</MDBRow>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<MDBRow>
									<MDBCol lg="4" middle>
										<p>{t("addticket.lastname")}</p>
									</MDBCol>
									<MDBCol lg="8">
										<input
											required
											id="profile"
											className="form-control"
											onChange={this.changeHandler}
											name="lastName"
											value={this.state.lastName}
										/>
										<div className="invalid-feedback">
											Please provide a valid profile name.
										</div>
									</MDBCol>
								</MDBRow>
							</MDBCol>
						</MDBRow>
					</form>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBBtn
						color="danger"
						onClick={this.submitHandler}
						type="submit"
					>
						{this.state.loading1 === true ? (
							<div
								className="spinner-border spinner-border-sm"
								role="status"
							>
								<span className="sr-only">Loading...</span>
							</div>
						) : (
							t("addticket.submit")
						)}
					</MDBBtn>
				</MDBModalFooter>
			</>
		);
	}
}
export default withTranslation()(MyAccount);