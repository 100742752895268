import React, { Component } from "react";

import {
	MDBBtn,
	MDBModalBody,
	MDBModalHeader,
	MDBRow,
	MDBCol,
} from "mdbreact";

import { withTranslation } from "react-i18next";

import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";

import $ from "jquery";

class AddNewResident extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Email: "",
			sms: false,
			voice: false,
			invalidPhone: null,
			loading: false,
			userFound: false,
		};
	}

	validateEmail = (email) => {
		var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
		this.setState({
			invalidPhone: undefined,
			userFound: false,
			userFoundWithPhone: false,
		});

		if (!this.state.email.match(mailformat)) {
			this.setState({ invalidEmail: "Please provide a valid email address" });
			$(".emailInput").addClass("is-invalid");
		} else {
			this.setState({ invalidEmail: undefined });
			$(".emailInput").removeClass("is-invalid");
		}
	};

	submitAddVenueAdministrators = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";
		if (this.state.invalidPhone === null || this.state.invalidPhone !== undefined) {
			this.setState({ invalidPhone: "Please provide a valid phone number" });
			$(".PhoneInput").addClass("invalid");
		}
		if (
			this.state.invalidPhone === undefined &&
			this.state.firstName !== undefined &&
			this.state.lastName !== undefined &&
			this.state.phoneNumber !== undefined &&
			this.state.language !== undefined 
		) {
			this.props.submitAddVenueAdministrators(
				this.state.email,
				this.state.firstName,
				this.state.lastName,
				this.state.phoneNumber,
				this.state.language,
				this.state.sms,
				this.state.voice,
				"VenueAdministrator"
			);
		}
	};

	toggleCheckboxValueSMS = () => {
		this.setState({ sms: !this.state.sms });
	};

	toggleCheckboxValueVoice = () => {
		this.setState({ voice: !this.state.voice });
	};

	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	changeHandlerPhone = (event) => {
		this.setState({ phoneNumber: event });
		var value = event ? (isPossiblePhoneNumber(event) ? undefined : "Please provide a valid phone number") : "Phone number required";
		this.setState({ invalidPhone: value });
	};
	createMask = () => {
		this.setState({ phoneNumber: this.state.phoneNumber.replace(/\D/g, "") });
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<MDBModalHeader toggle={this.props.toggleAddVenueAdministrators}>
					<h4>Add Venue Administrator</h4>
					<p>{t("addnewresident.required")}</p>
				</MDBModalHeader>
				<MDBModalBody>
					<form className="needs-validation" onSubmit={this.submitAddVenueAdministrators} noValidate>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									Venue Name*
								</label>
								<input
									disabled
									name="venueName"
									id="venueName"
									className="form-control"
									onChange={this.changeHandler}
									value={this.props.venueName}
								/>
								<div className="invalid-feedback">Please provide a valid name</div>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="12">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									{t("addnewresident.email")}
								</label>
								<input
									required
									id="email"
									type="email"
									className="form-control emailInput"
									onBlur={(event) => this.validateEmail(this.state.email)}
									onChange={(event) => this.setState({ email: event.target.value })}
									value={this.state.email}
								/>
								<div className="invalid-feedback">Please provide a valid email.</div>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									{t("addnewresident.firstname")}
								</label>
								<input
									required
									name="firstName"
									id="firstName"
									className={this.state.loading === true ? "form-control loading" : "form-control"}
									onChange={this.changeHandler}
									value={this.state.firstName}
									disabled={this.state.userFound}
								/>
								<div className="invalid-feedback">Please provide a valid first name.</div>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
									{t("addnewresident.lastname")}
								</label>
								<input
									required
									className={this.state.loading === true ? "form-control loading" : "form-control"}
									name="lastName"
									id="lastName"
									onChange={this.changeHandler}
									value={this.state.lastName}
									disabled={this.state.userFound}
								/>
								<div className="invalid-feedback">Please provide a valid last name.</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="6">
								<label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  {t("addnewresident.phone")}*
								</label>
								<PhoneInput
									placeholder={t("addnewresident.enterphone")}
									value={this.state.phoneNumber}
									onChange={this.changeHandlerPhone}
									name="phoneNumber"
									id="phoneNumber"
									className={this.state.loading === true ? "form-control loading" : "form-control"}
									required
									error={
										this.state.phoneNumber
											? isValidPhoneNumber(this.state.phoneNumber)
												? undefined
												: "Invalid phone number"
											: "Phone number required"
									}
									disabled={this.state.userFound}
								/>
								{this.state.invalidPhone !== null ? <div className="invalid">{this.state.invalidPhone}</div> : ""}
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<div>
									<label htmlFor="defaultFormLoginEmailEx" className="black-text">
										{t("addnewresident.language")}
									</label>
									<div className="select-box">
									<select
										className="browser-default custom-select"
										name="language"
										required
										onChange={this.changeHandler}
										value={this.state.language}
										disabled={this.state.userFound}
									>
										<option value="" disabled selected>
											{t("addnewresident.select")}
										</option>
										<option value="en-us">English</option>
										<option value="es-xl">Spanish</option>
									</select>
									</div>
									<div className="invalid-feedback">Please provide a valid language.</div>
								</div>
							</MDBCol>
						</MDBRow>
						<div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								{this.props.add_venueadmin === true ? (
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								) : (
									t("addstaff.addstaff")
								)}
							</MDBBtn>
						</div>
					</form>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(AddNewResident);