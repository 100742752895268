import React from 'react';

import { MDBDataTableV5 } from 'mdbreact';

import { useTranslation } from 'react-i18next';

export default function Basic(props) {
  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

  let datatable;
    [datatable] = React.useState('');
  if(props.radiusServerData!==undefined){

    userAttributes = [];
      // console.log(props.radiusServerData["RadiusServerIP"])
      userAttributes.push({
          serverIP: props.radiusServerData["RadiusServerIP"],
          action: <><div className="renewConfirmButton1"><a href="#link" onClick={()=>props.removeRadiusServerConfirm(0)} className="expirationAction">Remove</a></div></>
          
        })
  }
  
    datatable = ({
    columns: [
      
      {
        label: "Server IP",
        field: 'serverIP'
      },
      {
        label: sessionStorage.getItem("customerSupport") > 0 ? "" : "Action",
        field: sessionStorage.getItem("customerSupport") > 0 ? "" : "action",
      }
      
    ],
    rows: userAttributes
  });
  return <MDBDataTableV5 
          
            responsive 
            hover 
            data={datatable} 
            searchBottom={false} 
            paging={false}
            className="servicePlans"
            info={false}
            noRecordsFoundLabel={t('recentservice.nomatches')}
            />;
}