/* Login screen */
import React, { useState, useEffect, Suspense } from "react";
import Footer from "../../components/Common/Footer";
import { useHistory } from "react-router-dom";
let LoginPage;
export default function Login(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [state, setState] = useState({
    message: undefined,
    toggle: true,
    modal: window.location.pathname === "/terms" ? true : false,
    modalReset: window.location.pathname === "/reset" ? true : false,
    modalResetConfirm:
      window.location.pathname === "/reset_confirm" ? true : false,
    modalFAQ: window.location.pathname === "/help" ? true : false,
    focus: false,
    logo: undefined,
  });
  const history = useHistory();
  if (!hasLoaded) {
    loginBrandPage(props.customBrand);
  }
  useEffect(() => {
    LoginPage = React.lazy(() =>
      import("../../brands/" + props.customBrand + "/components/Login/Login")
    );
  }, [props]); //
  function loginBrandPage(brand) {
    LoginPage = React.lazy(() =>
      import("../../brands/" + brand + "/components/Login/Login")
    );
    setHasLoaded(true);
  }
  //
  function toggleAddProfile() {
    setState({
      modal: !state.modal,
    });
    if (!state.modal === false) {
      history.push("/");
    }
  }
  function toggleReset() {
    setState({
      modalReset: !state.modalReset,
    });
    if (!state.modalReset === false) {
      history.push("/");
    }
  }
  function toggleResetConfirm() {
    setState({
      modalResetConfirm: !state.modalResetConfirm,
    });
    if (!state.modalResetConfirm === false) {
      history.push("/");
    }
  }
  function toggleFAQ() {
    setState({
      modalFAQ: !state.modalFAQ,
    });
    if (!state.modalFAQ === false) {
      history.push("/");
    }
  }
  console.log(props)
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <LoginPage
          brand={props.brand}
          customBrand={props.customBrand}
          data={props.data}
          logoLogin={props.logoLogin}
          logoMobile={props.logoMobile}
          flagFooter={props.flagFooter}
          flagSignup={props.flagLoginSignup}
          toggleAddProfile={toggleAddProfile}
          toggleReset={toggleReset}
          toggleResetConfirm={toggleResetConfirm}
          toggleFAQ={toggleFAQ}
          modal={state.modal}
          modalReset={state.modalReset}
          modalResetConfirm={state.modalResetConfirm}
          modalFAQ={state.modalFAQ}
          setState={setState}
          operatorName={props.operatorName}
        />
        {props.flagFooter ? <Footer /> : ""}
      </Suspense>
    </>
  );
}
