
import React from 'react';

import { MDBDataTableV5 } from 'mdbreact';

import { useTranslation } from 'react-i18next';

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;

    let datatable;
    [datatable] = React.useState('');
    if (props.cpeEquipment !== undefined) {

        userAttributes = [];
        props.cpeEquipment["Devices"].forEach(prtgDevice => {
            let Unit = "N/A";
            let Bldg = "N/A";
            let Floor = "N/A";
            if (prtgDevice.UNIT !== undefined && prtgDevice.UNIT.questions.length > 0) {
                prtgDevice.UNIT.questions.forEach(question => {
                    if (question.question.toUpperCase().includes("UNITID")) {
                        Unit = question.answer;
                    }
                    if (question.question.toUpperCase().includes("FLOOR")) {
                        Floor = question.answer;
                    }
                    if (question.question.toUpperCase().includes("BUILDING")) {
                        Bldg = question.answer;
                    }
                });
            }
            userAttributes.push({
                Venue: props.selectedName,
                device: prtgDevice.DeviceName,
                type: prtgDevice.DeviceType,
                status: prtgDevice.Status,
                lastseen: prtgDevice.LastUp.split("<span")[0],
                unit: Unit,
                bldg: Bldg,
                floor: Floor
            });
        });
    }

    datatable = ({
        columns: [

            
            {
                label: 'Unit',
                field: 'unit',

            },
            {
                label: 'Bldg',
                field: 'bldg',

            },
            {
                label: 'Floor',
                field: 'floor',

            },
            {
                label: 'Device Name',
                field: 'device',

            },
            {
                label: 'Device Type',
                field: 'type',

            },
            {
                label: 'Status',
                field: 'status',

            },
            {
                label: 'Last Seen',
                field: 'lastseen',

            },
        ],
        rows: userAttributes
    });
    return <MDBDataTableV5

        responsive
        hover
        data={datatable}
        searchTop={true}
            searchBottom={false} 
            searchLabel="Filter"
        paging={false}
        className="recentResidents"
        info={false}
        noRecordsFoundLabel={t('recentservice.nomatches')}
    />;
}