/* Find Deals screen */
import React, { Component, Suspense } from "react";
import axios from "../../axios";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import $ from "jquery";
import "../../brands/common/css/common.scss";
import Footer from "../../components/Common/Footer";
// import unitsToFlag from "../../brands/gigstreem/components/CreateAccount/twoTreesSpecialConfirmation.json";
import unitsToFlag from "../../brands/dish/components/unitsConfirmationTest.json";
let CreateAccount1Component = React.lazy(() =>
  import(
    "../../brands/" +
      sessionStorage.getItem("customBrand") +
      "/components/CreateAccount/CreateAccountP1"
  )
);
const root = document.documentElement;
root?.style.setProperty(
 "--background-color",
 sessionStorage.getItem("background-color")
);
root?.style.setProperty(
 "--modal-background-color",
 sessionStorage.getItem("modal-background-color")
);
root?.style.setProperty(
 "--primary-text-color",
 sessionStorage.getItem("primary-text-color")
);
root?.style.setProperty("--primary-color", sessionStorage.getItem("primary-color"));
root?.style.setProperty(
 "--secondary-color",
 sessionStorage.getItem("secondary-color")
);
root?.style.setProperty(
 "--secondary-text-color",
 sessionStorage.getItem("secondary-text-color")
);
root?.style.setProperty(
 "--border-color",
 sessionStorage.getItem("border-color")
);
root?.style.setProperty(
 "--primary-color-rgb",
 sessionStorage.getItem("base-color-rgb")
);
root?.style.setProperty("--link-color", sessionStorage.getItem("link-color"));
root?.style.setProperty(
 "--button-color",
 sessionStorage.getItem("button-color")
);
root?.style.setProperty("--button-text-color", "#FFF");
root?.style.setProperty(
 "--input-color",
 sessionStorage.getItem("input-color")
);
root?.style.setProperty(
 "--input-focus-color",
 sessionStorage.getItem("input-focus-color")
);

root?.style.setProperty(
 "--banner-color-1",
 sessionStorage.getItem("banner-color-1")
);
root?.style.setProperty(
 "--banner-color-2",
 sessionStorage.getItem("banner-color-2")
)
class CreateAccount1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: sessionStorage.getItem("firstName"),
      lastName: sessionStorage.getItem("lastName"),
      phoneNumber: sessionStorage.getItem("phoneNumber"),
      email: sessionStorage.getItem("email"),
      optionTemplateUnit: [],
      unit: sessionStorage.getItem("unit"),
      sms: undefined,
      dateStart: sessionStorage.getItem("dateStart"),
      disableButton: "disabled",
      daysofservice: sessionStorage.getItem("daysofservice"),
      billingCycleRecurrence: sessionStorage.getItem("billingCycleRecurrence"),
    };
  }
  componentDidMount() {
    this.getVenueUnits();
  }
  getVenueUnits = () => {
    let optionTemplateUnit = [];
    let body = {
      VenueID: sessionStorage.getItem("venueID"),
      ServicePlanID: sessionStorage.getItem("servicePlanID"),
    };
    axios
      .post("/networkoperatorconsole/shoppingcart/listVenueUnits", body, {
        headers: {},
      })
      .then((response) => {
       Object.keys(
        response.data["VenueUnits"]
      ).map((oneKey, i) => {
        let optionName =
          "Unit: " +
          response.data["VenueUnits"][i][
            "UnitID"
          ] +
          ", Building: " +
          response.data["VenueUnits"][i][
            "UnitBuilding"
          ] +
          " \r\n " +
          response.data["VenueUnits"][i][
            "AddressLine1"
          ] +
          " " +
          response.data["VenueUnits"][i][
           "AddressLine2"
          ];
        optionTemplateUnit.push({
          value:
            response.data["VenueUnits"][i][
              "UnitID_internal"
            ],
          name: optionName,
        });
        return true;
      });
       // console.log(window.location.href.indexOf("gigstreem"))
       //  if(window.location.href.indexOf("gigstreem") > -1 ){
       //   Object.keys(
       //    response.data["VenueUnits"]
       //  ).map((oneKey, i) => {
       //    let optionName =
       //      "Unit: " +
       //      response.data["VenueUnits"][i][
       //        "UnitID"
       //      ] +
       //      ", Building: " +
       //      response.data["VenueUnits"][i][
       //        "UnitBuilding"
       //      ] +
       //      " \r\n " +
       //      response.data["VenueUnits"][i][
       //        "AddressLine1"
       //      ] +
       //      " " +
       //      response.data["VenueUnits"][i][
       //       "AddressLine2"
       //      ];
       //    optionTemplateUnit.push({
       //      value:
       //        response.data["VenueUnits"][i][
       //          "UnitID_internal"
       //        ],
       //      name: optionName,
       //    });
       //    return true;
       //  });
       //  }else{
       //   Object.keys(
       //    response.data["VenueUnits"]
       //  ).map((oneKey, i) => {
       //    let optionName =
       //      response.data["VenueUnits"][i][
       //        "UnitID"
       //      ] +
       //      " \r\nBuilding: " +
       //      response.data["VenueUnits"][i][
       //        "UnitBuilding"
       //      ] +
       //      ", Floor: " +
       //      response.data["VenueUnits"][i][
       //        "UnitFloor"
       //      ];
       //    optionTemplateUnit.push({
       //      value:
       //        response.data["VenueUnits"][i][
       //          "UnitID_internal"
       //        ],
       //      name: optionName,
       //    });
       //    return true;
       //  });
       //  }
        
        this.setState({ optionTemplateUnit: optionTemplateUnit });
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    sessionStorage.setItem(event.target.name, event.target.value);
  };
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
    sessionStorage.setItem(name, value);
  };
  handleChangeDate = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ dateStart: value });
    }
    this.getVenueUnits();
    sessionStorage.setItem("dateStart", value);
  };
  toggleCheckboxValueSMS = () => {
    this.setState({ sms: !this.state.sms });
    sessionStorage.setItem("sms", !this.state.sms);
  };
  handleSelectUnit = (event, { name, value }) => {
    this.setState({ unit: value });
    // eslint-disable-next-line no-unused-vars
    let flagUnit;
    unitsToFlag.forEach(({ unitID }) => {
      if (unitID.includes(value)) {
        sessionStorage.setItem("flagUnit", true);
        flagUnit = true;
      } else {
        sessionStorage.setItem("flagUnit", false);
      }
    });
    sessionStorage.setItem("unit", value);
    sessionStorage.setItem("unitName", name);
  };
  changeHandlerPhone = (event) => {
    this.setState({ phoneNumber: event });
    var value = event
      ? isPossiblePhoneNumber(event)
        ? undefined
        : "Please provide a valid phone number"
      : "Phone number required";
    this.setState({ invalidPhone: value });
    sessionStorage.setItem("phoneNumber", event);
  };
  validateEmail = (email) => {
    var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if (!this.state.email.match(mailformat)) {
      this.setState({
        invalidEmail: "Please provide a valid email address",
      });
      $(".emailInput").addClass("is-invalid");
    } else {
      this.setState({ invalidEmail: undefined });
      $(".emailInput").removeClass("is-invalid");
    }
  };
  render() {
    return (
      <>
        <Suspense
          fallback={
            <div className="overlay" id="overlay" style={{ display: "block" }}>
              <div className="overlayMessage">
                <div className="spinner-border spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          }
        >
          <CreateAccount1Component
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            phoneNumber={this.state.phoneNumber}
            email={this.state.email}
            optionTemplateUnit={this.state.optionTemplateUnit}
            unit={this.state.unit}
            sms={this.state.sms}
            dateStart={this.state.dateStart}
            disableButton={"disabled"}
            daysofservice={parseInt(this.state.daysofservice)}
            billingCycleRecurrence={this.state.billingCycleRecurrence}
            changeHandler={this.changeHandler}
            handleChange={this.handleChange}
            handleChangeDate={this.handleChangeDate}
            handleSelectUnit={this.handleSelectUnit}
            changeHandlerPhone={this.changeHandlerPhone}
            loading={this.state.loading}
            invalidPhone={this.state.invalidPhone}
            invalidEmail={this.state.invalidEmail}
            toggleCheckboxValueSMS={this.toggleCheckboxValueSMS}
            validateEmail={this.validateEmail}
            logo={this.props.logoLogin}
            brand={this.props.brand}
          />
          <Footer />
        </Suspense>
      </>
    );
  }
}
export default CreateAccount1;
