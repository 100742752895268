import React, { Component } from "react";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBIcon,
  MDBNavItem,
  MDBNavLink,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBCollapse,
} from "mdbreact";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import MyAccount from "../../../components/SubscriberMgmt/MyAccount/MyAccount";

import Support from "../../../components/SubscriberMgmt/Support/Support";

import ViewTickets from "../../../components/SubscriberMgmt/Support/ViewTickets";

import ViewTransactions from "../../../components/SubscriberMgmt/Transactions/ViewTransactions";

import LanguageSwitch from "../Navigation/LanguageSwitch/LanguageSwitch";

import axios from "../../../axios";

import FAQ from "../../../components/VenueMgmt/Support/FAQs";

import { withTranslation } from "react-i18next";

import Toggle from "react-toggle";

import brandsData from "../../../brands/common/brands.json";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      darkmode: localStorage.getItem("darkMode") === "true" ? true : false,
    };
  }

  toggleSingleCollapse = (collapseId) => () => {
    console.log(collapseId);
    this.setState({
      [collapseId]: !this.state[collapseId],
    });
  };

  componentDidMount() {
    this.getSupportDetails();
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute("data-theme", "darkMode-" + this.state.darkmode);
  }

  onSignOutClick = () => {
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute("data-theme", "darkMode-false");
    // sessionStorage.clear();
    let key;
    let keysToRemove = [
      "RefreshToken",
      "userEmail",
      "networkAdmin",
      "subscriberToken",
      "isAdmin",
      "loggedInNO",
      "phoneNumber",
      "lastName",
      "AccessToken",
      "leasingAgent",
      "venueAdmin",
      "adminOf",
      "email",
      "accountManager",
      "firstName",
      "customerSupport",
      "venueName",
      "venueID",
      "dateStart",
      "emailSubscriber",
      "firstInvoiceDate",
      "newServicePlanID",
      "nextPaymentDate",
      "proratedPricePerHour",
      "servicePlanIDSubscriber",
      "stripeClientSecretSubscriber",
      "subscriptionID",
      "trialEndDate",
      "unbilledHoursTilNextPaymentDate",
      "unitID_internalSubscriber",
      "venueIDSubscriber",
      "clientSecret",
      "phoneNumberSubscriber",
      "servicePlanNameSubscriber",
      "servicePlanPriceSubscriber",
      "BillingCycleHours",
      "BillingCycleRecurrence",
      "AvailableServicePlans",
      "AvailableServicePlanCount",
      "AccessToken"
    ];

    for (key of keysToRemove) {
      sessionStorage.removeItem(key);
    }

    localStorage.removeItem("customBrand");
    this.setState({ isLogged: false });
  };

  toggleMyAccount = () => {
    this.setState({
      modalMyAccount: !this.state.modalMyAccount,
    });
  };

  toggleSupport = () => {
    this.setState({
      modalSupport: !this.state.modalSupport,
    });
  };

  toggleViewTickets = () => {
    this.setState({
      modalViewTickets: !this.state.modalViewTickets,
    });
  };

  toggleViewTransactions = () => {
    this.setState({
      modalViewTransactions: !this.state.modalViewTransactions,
    });
  };

  toggleFAQs = () => {
    this.setState({
      modalFAQ: !this.state.modalFAQ,
    });
  };

  toggleContactUs = () => {
    this.setState({
      modalContactUs: !this.state.modalContactUs,
    });
  };

  getSupportDetails = () => {
    if (this.props.venueName.length !== 0) {
      let body = {
        AccessToken: sessionStorage.getItem("AccessToken"),
        VenueID: sessionStorage.getItem("venueIDSubscriber"),
        email: sessionStorage.getItem("emailSubscriber"),
      };
      this.setState({
        ticketCount: undefined,
        tickets: undefined,
      });
      axios
        .post("/subscriberconsole/getTickets", body, {
          headers: {},
        })
        .then((response) => {
          this.setState({
            ticketCount: response.data.TicketCount,
            tickets: response.data.Tickets,
          });
        })
        .catch((error) => {
          this.setState({ error: true });
        });
    }
  };

  render() {
    // console.log(this.props)
    const { t } = this.props;
    let showTour;
    let showNetworkOpAdmin;
    let showSupport;
    let showTransactions;
    // eslint-disable-next-line no-unused-vars
    let email;
    // eslint-disable-next-line no-unused-vars
    let phoneNumber;
    let showTranslation;
    let externalSupport;
    let externalSupportLink;
    let loginURL;
    sessionStorage.getItem("fromAuthToken")
      ? (loginURL = "/logout")
      : (loginURL = sessionStorage.getItem("loginPage"));
    brandsData.forEach(
      ({
        name,
        url,
        phone_number,
        email_address,
        flag_showTour,
        flag_showNetworkOpAdmin,
        flag_showSupport,
        flag_showTransactions,
        flag_showTranslation,
        external_support,
        external_support_link,
      }) => {
        if (url.includes(window.location.hostname)) {
          showTour = flag_showTour;
          showNetworkOpAdmin = flag_showNetworkOpAdmin;
          // showSupport = flag_showSupport;
          showTransactions = flag_showTransactions;
          email = email_address;
          // phoneNumber = phone_number;
          showTranslation = flag_showTranslation;
          // externalSupport = external_support;
          // externalSupportLink = external_support_link;
        }
      }
    );
    return (
      <>
        <MDBModal
          isOpen={this.state.modalMyAccount}
          toggle={this.toggleMyAccount}
          size="lg"
          className="form"
          centered
        >
          <MyAccount
            toggleMyAccount={this.toggleMyAccount}
            allData={this.props.allData}
            listKeys={this.props.listKeys}
            SessionList={this.props.SessionList}
            keys={this.props.keys}
            venueName={this.props.venueName}
            UpgradeServicePlanName={this.props.UpgradeServicePlanName}
            showCancel={this.props.showCancel}
            showUpgrade={this.props.showUpgrade}
            showUpdate={this.props.showU}
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalSupport}
          toggle={this.toggleSupport}
          size="lg"
          className="form"
          centered
        >
          <Support
            toggleSupport={this.toggleSupport}
            allData={this.props.allData}
            venueID={this.props.selectedID}
            keys={this.props.keys}
            venueName={this.props.venueName}
            getSupportDetails={this.getSupportDetails}
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalViewTickets}
          toggle={this.toggleViewTickets}
          size="fluid"
          className="form"
          centered
        >
          <ViewTickets
            toggleViewTickets={this.toggleViewTickets}
            allData={this.props.allData}
            tickets={this.state.tickets}
            keys={this.props.keys}
            venueName={this.props.venueName}
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalViewTransactions}
          toggle={this.toggleViewTransactions}
          size="fluid"
          className="form"
          centered
        >
          <ViewTransactions
            toggleViewTransactions={this.toggleViewTransactions}
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalFAQ}
          toggle={this.toggleFAQs}
          size="lg"
          className="form"
          centered
        >
          <FAQ toggleFAQs={this.toggleFAQs} />
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalContactUs}
          toggle={this.toggleContactUs}
          size="sm"
          className="form"
          centered
        >
          <MDBModalHeader toggle={this.toggleContactUs}>
            <h4>{t("subnavigation.contactus")}</h4>
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <p>
                {t("subnavigation.email")}:{" "}
                <a href={"mailto:" + sessionStorage.getItem("supportEmail")}>
                  {sessionStorage.getItem("supportEmail")}
                </a>
              </p>
              <p>
                {t("subnavigation.phone")}:{" "}
                <a href={"tel:" + sessionStorage.getItem("supportPhone")}>
                  {sessionStorage.getItem("supportPhone")}
                </a>
              </p>
            </div>
          </MDBModalBody>
        </MDBModal>
        <MDBNavbar className="subscriberNavbar">
          <div
            className="Logo first-step"
            darkMode={localStorage.getItem("darkMode")}
          >
            {<img src={sessionStorage.getItem("logoURL")} alt="Logo" />}
          </div>
          <div className="networkName">
            <h1>{t("subnavigation.title")}</h1>
          </div>

          <div className="account">
            <MDBNavbar color="white lighten-4" light right>
              <MDBNavbarBrand></MDBNavbarBrand>
              <label>
                <Toggle
                  className="colorMode"
                  defaultChecked={this.state.darkmode}
                  icons={{
                    unchecked: <MDBIcon icon="sun" />,
                    checked: <MDBIcon icon="moon" />,
                  }}
                  onChange={this.props.handleSwitchChange(1)}
                />
              </label>
              {showTranslation ? (
                <LanguageSwitch listKeys={this.props.listKeys} />
              ) : (
                ""
              )}

              <IconButton
                onClick={this.toggleSingleCollapse("collapse1")}
                size="small"
                sx={{ ml: 2 }}
              >
                <Avatar variant="square">
                  <MenuIcon fontSize="large" />
                </Avatar>
              </IconButton>
              <MDBCollapse
                id="navbarCollapse1"
                isOpen={this.state.collapse1}
                navbar
              >
                <MDBNavbarNav left>
                  <MDBNavItem active>
                    <div
                      onClick={this.toggleSingleCollapse("collapse2")}
                      className="topMenuItem"
                    >
                      <p>
                        <MDBIcon
                          icon="concierge-bell"
                          data-toggle="tooltip"
                          title="Support Settings"
                        />
                        Support
                        <MDBIcon icon="sort-down" />
                      </p>
                    </div>
                    <MDBCollapse
                      id="navbarCollapse2"
                      isOpen={this.state.collapse2}
                      navbar
                    >
                      <MDBNavItem>
                        {this.props.showSupport === true ? (
                          this.props.externalSupport ? (
                            <>
                              <a
                                href={this.props.externalSupportLink}
                                target="_blank"
                                className="nav-link Ripple-parent active"
                                rel="noreferrer"
                              >
                                {t("subnavigation.addticket")}
                              </a>
                            </>
                          ) : (
                            <>
                              <MDBNavLink
                                to="#!"
                                onClick={this.toggleViewTickets}
                              >
                                {t("subnavigation.viewtickets")}
                              </MDBNavLink>
                              <MDBNavLink to="#!" onClick={this.toggleSupport}>
                                {t("subnavigation.addticket")}
                              </MDBNavLink>
                            </>
                          )
                        ) : (
                          ""
                        )}
                        <MDBNavLink to="#!" onClick={this.toggleFAQs}>
                          FAQs
                        </MDBNavLink>
                        <MDBNavLink to="#!" onClick={this.toggleContactUs}>
                          {t("subnavigation.contactus")}
                        </MDBNavLink>
                      </MDBNavItem>
                    </MDBCollapse>
                  </MDBNavItem>
                  {sessionStorage.getItem("fromAuthToken") ? (
                    ""
                  ) : (
                    <MDBNavItem>
                      <div
                        onClick={this.toggleSingleCollapse("collapse3")}
                        className="topMenuItem"
                      >
                        <p>
                          <MDBIcon
                            icon="user"
                            data-toggle="tooltip"
                            title="Account Settings"
                          />
                          My Account
                          <MDBIcon icon="sort-down" />
                        </p>
                      </div>
                      <MDBCollapse
                        id="navbarCollapse3"
                        isOpen={this.state.collapse3}
                        navbar
                      >
                        <MDBNavLink to="#!" onClick={this.toggleMyAccount}>
                          {t("subnavigation.accountsettings")}
                        </MDBNavLink>
                        {showTransactions === true ? (
                          <MDBNavLink
                            to="#!"
                            onClick={this.toggleViewTransactions}
                          >
                            View Transactions
                          </MDBNavLink>
                        ) : (
                          ""
                        )}
                        <MDBNavItem>
                          {sessionStorage.getItem("venueAdmin") > 0 ||
                          sessionStorage.getItem("leasingAgent") > 0 ||
                          sessionStorage.getItem("accountManager") > 0 ||
                          sessionStorage.getItem("networkAdmin") > 0 ? (
                            <MDBNavLink to="/dashboard">
                              {t("subnavigation.venueadmin")}
                            </MDBNavLink>
                          ) : (
                            ""
                          )}
                          {sessionStorage.getItem("networkAdmin") > 0 &&
                          showNetworkOpAdmin === true ? (
                            <MDBNavLink to="/networkdashboard">
                              {t("subnavigation.networkadmin")}
                            </MDBNavLink>
                          ) : (
                            ""
                          )}
                          {showTour === true ? (
                            <MDBNavLink to="#!" onClick={this.props.closeTour}>
                              Show Tour
                            </MDBNavLink>
                          ) : (
                            ""
                          )}
                        </MDBNavItem>
                      </MDBCollapse>
                    </MDBNavItem>
                  )}
                  <MDBNavItem>
                    <MDBNavLink to={loginURL} onClick={this.onSignOutClick}>
                      {t("subnavigation.logout")}
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
          </div>
        </MDBNavbar>
      </>
    );
  }
}

export default withTranslation()(Navigation);
