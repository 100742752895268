/* /terms */
import React from "react";

import { useEffect } from "react";

import axios from "../../axios";

import queryString from "query-string";

import $ from "jquery";

export default function Login() {
  const parsed = queryString.parse(window.location.search);

  const mount = () => {
    let data;
    let locale;
    if (parsed["locale"]) {
      locale = parsed["locale"];
    } else {
      if (
        sessionStorage.getItem("i18nextLng") === "en_us" ||
        sessionStorage.getItem("i18nextLng") === "en" ||
        sessionStorage.getItem("i18nextLng") === "en-US"
      ) {
        locale = "en-us";
      }
    }
    data = {
      locale: locale,
    };
    axios
            .post("/subscriberconsole/viewTandC", data, {
        headers: {},
      })
      .then((response) => {
        $(".terms").append(response.data.TandC);
      })
      .catch((error) => {
        // console.log("🚀 ~ file: TandC.js:42 ~ mount ~ error", error)
      });

    return mount;
  };

  useEffect(() => {
    mount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="terms" className="terms"></div>
    </>
  );
}
