import React, { Component } from "react";

import { MDBModalBody, MDBModalHeader, MDBRow, MDBCol } from "mdbreact";

import PendingGatewayVLANEnableTable from "../../../components/NetworkMgmt/Support/Datatables/PendingGatewayVLANEnableTable";
class EquipmentErrorLog extends Component {

    state = {
		pendingGatewayVLANEnable: this.props.pendingGatewayVLANEnable
	};

	render() {
		return (

<div className="logList">
<MDBModalHeader toggle={this.props.togglelistPendingGatewayVLANEnable}>
          <h4>Pending Gateway VLAN Enable</h4>
        </MDBModalHeader>
        <MDBModalBody>
								<MDBRow>
									<MDBCol col="12">
										{this.state.pendingGatewayVLANEnable.length !== 0 ?
											<PendingGatewayVLANEnableTable
                                                pendingGatewayVLANEnable={this.props.pendingGatewayVLANEnable}
                                            />
											:
											<div className="spinner-border spinner-border-sm" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										}
									</MDBCol>

								</MDBRow>
                                </MDBModalBody>
							</div>
                            
                            );
                        }
                    }

export default EquipmentErrorLog;