import React, { Component } from "react";

import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBIcon,
  MDBBtn,
  toast,
} from "mdbreact";

import axios from "../../../axios";

import ResidentManagementNavigation from "../Navigation/ResidentManagementNavigation/ResidentManagementNavigation";

import VenueManagementNavigation from "../Navigation/VenueManagementNavigation/VenueManagementNavigation";

import SettingsNavigation from "../Navigation/SettingsNavigation/SettingsNavigation";

import SupportNavigation from "../Navigation/SupportNavigation/SupportNavigation";

import Logo from "../../../components/Logo/Logo";

import { withTranslation } from "react-i18next";

import LanguageSwitch from "../../../components/VenueMgmt/Navigation/LanguageSwitch/LanguageSwitch";

import MyAccount from "../../../components/VenueMgmt/MyAccount/MyAccount";

import Toggle from "react-toggle";

import brandsData from "../../../brands/common/brands.json";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLogged: false,
      modalResidentManagement: false,
      modalGuestManagement: false,
      modalSupport: false,
      modalPropertySettings: false,
      modalSupportSettings: false,
      modalMyAccount: false,
      venueList2: [],
      keyPoolForm: [],
      venueListName: [],
      darkmode: localStorage.getItem("darkMode") === "true" ? true : false,
    };
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelectChange = (value) => {
    var venue = document.getElementsByClassName("venueSelected")[0].value;

    var lDiv = document.createElement("div");

    document.body.appendChild(lDiv);

    lDiv.style.fontSize = "" + 20 + "px";
    lDiv.style.position = "absolute";
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;

    lDiv.innerHTML = venue;

    var lResult = {
      width: lDiv.clientWidth,
      height: lDiv.clientHeight,
    };

    document.body.removeChild(lDiv);
    lDiv = null;
    document.getElementById("selectVenue").style.width =
      lResult["width"] + 30 + "px";
    this.props.updateName(venue, value[0]);
    this.setState({ selectedID: value[0] });
  };

  toggleResidentManagement = () => {
    this.setState({
      modalResidentManagement: !this.state.modalResidentManagement,
    });
  };

  toggleGuestManagement = () => {
    this.setState({
      modalGuestManagement: !this.state.modalGuestManagement,
    });
  };

  toggleEquipment = () => {
    this.setState({
      modalEquipment: !this.state.modalEquipment,
    });
  };

  toggleSupportSettings = () => {
    this.setState({
      modalSupportSettings: !this.state.modalSupportSettings,
    });
  };

  toggleOperatorSettings = () => {
    this.setState({
      modalOperatorSettings: !this.state.modalOperatorSettings,
    });
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleResetPassword = () => {
    this.setState({
      modalResetPassword: !this.state.modalResetPassword,
    });
  };

  toggleMyAccount = () => {
    this.setState({
      modalMyAccount: !this.state.modalMyAccount,
    });
  };

  resetPassword = () => {
    let data;
    data = {
      EmailAddress: sessionStorage.getItem("email"),
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    axios
      .post("/subscriberconsole/initiateResetConsolePassword", data, {
        headers: {},
      })
      .then((response) => {
        this.toggleResetPassword();
        toast.success(
          "Password reset initiated, please check your email for instructions!",
          {
            closeButton: false,
          }
        );
      })
      .catch((error) => {
        toast.success("An error occurred", {
          closeButton: false,
        });
      });
  };

  componentDidMount() {
    this._isMounted = true;
    this.getOperatorInfo();
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute("data-theme", "darkMode-" + this.state.darkmode);
  }

  getOperatorInfo = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    this.setState({
      operatorInfo: undefined,
    });
    axios
      .post("/networkoperatorconsole/equipment/listOperatorDetails", body, {
        headers: {},
      })
      .then(
        (response) => {
          this.setState({ operatorName: response.data.NetworkOperatorName });
          sessionStorage.setItem(
            "cartEnabledGlobally",
            response.data.ShoppingCart.enableShoppingCart
          );
        },
        (error) => {
          console.log(
            "🚀 ~ file: Navigation.js:191 ~ Navigation ~ error",
            error
          );
        }
      );
  };

  toggleTab = (tab) => () => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  onSignOutClick = () => {
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute("data-theme", "darkMode-false");
    let key;
    let keysToRemove = [
      "RefreshToken",
      "userEmail",
      "networkAdmin",
      "subscriberToken",
      "isAdmin",
      "loggedInNO",
      "phoneNumber",
      "lastName",
      "AccessToken",
      "leasingAgent",
      "venueAdmin",
      "adminOf",
      "email",
      "accountManager",
      "firstName",
      "customerSupport",
      "venueName",
      "venueID",
      "dateStart",
      "emailSubscriber",
      "firstInvoiceDate",
      "newServicePlanID",
      "nextPaymentDate",
      "proratedPricePerHour",
      "servicePlanIDSubscriber",
      "stripeClientSecretSubscriber",
      "subscriptionID",
      "trialEndDate",
      "unbilledHoursTilNextPaymentDate",
      "unitID_internalSubscriber",
      "venueIDSubscriber",
      "clientSecret",
    ];

    for (key of keysToRemove) {
      sessionStorage.removeItem(key);
    }
    localStorage.removeItem("customBrand")
    this.setState({ isLogged: false });
  };
  handleSwitchChange = (nr) => () => {
    this.setState({
      darkmode: !this.state.darkmode,
    });
    localStorage.setItem("darkMode", !this.state.darkmode);
    let element = document.getElementsByTagName("body")[0];
    element.setAttribute("data-theme", "darkMode-" + !this.state.darkmode);
  };

  render() {
    if (this.state.postMessage) {
      var h = document.getElementById("overlay");
      h.style.display = "block";
      setTimeout(function () {
        h.style.display = "block";
      }, 5000);
      setTimeout(() => {
        h.style.display = "none";
        if (
          this.state.postMessage !== "Something went wrong, please try again"
        ) {
          this.setState({ postResponse: "" });
        }
        if (
          this.state.postMessage ===
          "Your session has expired. Please log in again to continue."
        ) {
          window.location.replace("/");
        }
      }, 6000);
    }

    let showTranslation;
    brandsData.forEach(({ name, url, flag_showTranslation }) => {
      if (url.includes(window.location.hostname)) {
        showTranslation = flag_showTranslation;
      }
    });

    const { t } = this.props;

    let loginURL;
    sessionStorage.getItem("fromAuthToken")
      ? (loginURL = "/logout")
      : (loginURL = sessionStorage.getItem("loginPage"));
    return (
      <>
        <div className="overlay" id="overlay">
          <div id="overlayMessage" className="overlayMessage">
            {this.state.postMessage}
          </div>
        </div>
        <MDBModal
          isOpen={this.state.modalResetPassword}
          toggle={this.toggleResetPassword}
          size="sm"
          className="smallModal"
          centered
        >
          <MDBModalHeader toggle={this.toggleResetPassword}>
            <h4>Reset Password</h4>
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <MDBBtn
                onClick={this.resetPassword}
                color="danger"
                block
                bsSize="large"
                type="submit"
              >
                Reset Password
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalMyAccount}
          toggle={this.toggleMyAccount}
          size="lg"
          className="form"
          centered
        >
          <MyAccount
            toggleMyAccount={this.toggleMyAccount}
            allData={this.props.allData}
            listKeys={this.props.listKeys}
            SessionList={this.props.SessionList}
            keys={this.props.keys}
            venueName={this.props.venueName}
          />
        </MDBModal>
        <MDBNavbar color="" dark expand="md">
        <div class="Logo first-step" darkmode="false">
            {
              <img
                src={
                  
                  this.props.logo
                }
                alt="Logo"
              />
            }
          </div>
          <MDBNavbarNav>
            <div className="mainNavigation">
              <>
                <MDBNavItem>
                  <MDBNavLink
                    to="#"
                    className="firstNav"
                    onClick={this.toggleResidentManagement}
                  >
                    Subscribers
                  </MDBNavLink>
                  <MDBModal
                    isOpen={this.state.modalResidentManagement}
                    toggle={this.toggle}
                    size="lg"
                    centered
                  >
                    <ResidentManagementNavigation
                      toggle={this.toggleResidentManagement}
                      venueNameSelected={this.props.selectedName}
                      keypoolID={this.props.keypoolID}
                      venueID={this.props.selectedID}
                      venueListName={this.state.venueListName}
                      updateName={this.props.updateName}
                      activeData={this.props.activeData}
                      allActiveData={this.props.allActiveData}
                      expirations={this.props.expirations}
                      loading={this.props.loading}
                      venueType={this.props.venueType}
                      availablePlans={this.props.availablePlans}
                      getVenues={this.getVenues}
                      showUpdate={this.props.showUpdate}
                      cancelInputType={this.props.cancelInputType}
                      optionTemplateVenues={this.props.optionTemplateVenues}
                      unitsArrayAll={this.props.unitsArrayAll}
                      hasUpdated={this.props.hasUpdated}
                      venueInfo={this.props.venueInfo}
                      loadingVenueDetails={this.props.loadingVenueDetails}
                    />
                  </MDBModal>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="#" onClick={this.toggleGuestManagement}>
                    Venues
                  </MDBNavLink>
                  <MDBModal
                    isOpen={this.state.modalGuestManagement}
                    toggle={this.toggleGuestManagement}
                    size="lg"
                    centered
                  >
                    <VenueManagementNavigation
                      toggleGuestManagement={this.toggleGuestManagement}
                      venueNameSelected={this.props.selectedName}
                      keypoolID={this.props.keypoolID}
                      venueID={this.props.selectedID}
                      venueListName={this.state.venueListName}
                      updateName={this.props.updateName}
                      guestData={this.props.guestData}
                      guestDataExpiring={this.props.guestDataExpiring}
                      loading={this.props.loading}
                      venueType={this.props.venueType}
                      getVenues={this.props.getVenues}
                      getVenuesList={this.props.getVenuesList}
                      venueListForTable={this.props.venueListForTable}
                      sharedSecret={this.props.sharedSecret}
                      UnitsBuildingFloorListSusbcribers={
                        this.props.UnitsBuildingFloorListSusbcribers
                      }
                      venueName={this.props.venueName}
                      operatorName={this.state.operatorName}
                      optionTemplateVenues={this.props.optionTemplateVenues}
                      allowOverlapping={this.props.allowOverlapping}
                      displayOptionIDGlobal={this.props.displayOptionIDGlobal}
                      optionTemplateDisplayOptions={
                        this.props.optionTemplateDisplayOptions
                      }
                      unitsArrayAll={this.props.unitsArrayAll}
                      hasUpdated={this.props.hasUpdated}
                      venueInfo={this.props.venueInfo}
                      loadingVenueDetails={this.props.loadingVenueDetails}
                      keyPoolList={this.props.keyPoolList}
                      servicePlans={this.props.servicePlans}
                      staffArrayAll={this.props.staffArrayAll}
                      venueEquipList={this.props.venueEquipList}
                    />
                  </MDBModal>
                </MDBNavItem>
              </>
              <MDBNavItem>
                <MDBNavLink to="#" onClick={this.toggleSupportSettings}>
                  Support
                </MDBNavLink>
                <MDBModal
                  isOpen={this.state.modalSupportSettings}
                  toggle={this.toggleSupportSettings}
                  size="lg"
                  centered
                >
                  <SupportNavigation
                    toggleSupportSettings={this.toggleSupportSettings}
                    venueNameSelected={this.props.selectedName}
                    venueListName={this.state.venueListName}
                    updateName={this.props.updateName}
                    venueData={this.props.venueData}
                    venueID={this.props.selectedID}
                    venueAdmins={this.props.venueAdmins}
                    leasingAgents={this.props.leasingAgents}
                    loading={this.props.loading}
                    buildings={this.props.buildings}
                    getVenues={this.getVenues}
                    venueName={this.props.venueName}
                  />
                </MDBModal>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="#" onClick={this.toggleOperatorSettings}>
                  {t("navigation.settings")}
                </MDBNavLink>
                <MDBModal
                  isOpen={this.state.modalOperatorSettings}
                  toggle={this.toggleOperatorSettings}
                  size="lg"
                  centered
                >
                  <SettingsNavigation
                    toggleOperatorSettings={this.toggleOperatorSettings}
                    venueNameSelected={this.props.selectedName}
                    venueListName={this.state.venueListName}
                    updateName={this.props.updateName}
                    venueData={this.props.venueData}
                    venueID={this.props.selectedID}
                    venueAdmins={this.props.venueAdmins}
                    leasingAgents={this.props.leasingAgents}
                    loading={this.props.loading}
                    buildings={this.props.buildings}
                    getVenues={this.getVenues}
                    venueName={this.props.venueName}
                    displayOptionID={this.props.displayOptionID}
                  />
                </MDBModal>
              </MDBNavItem>
              <div className="account">
                <div className="darkModeToggle">
                  <label>
                    <Toggle
                      className="colorMode"
                      defaultChecked={this.state.darkmode}
                      icons={{
                        unchecked: <MDBIcon icon="sun" />,
                        checked: <MDBIcon icon="moon" />,
                      }}
                      onChange={this.props.handleSwitchChange(1)}
                    />
                  </label>
                </div>
                <div className="accountLogout">
                  {showTranslation ? (
                    <LanguageSwitch
                      venueID={this.props.selectedID}
                      venueNameSelected={this.props.selectedName}
                      updateName={this.props.updateName}
                    />
                  ) : (
                    ""
                  )}
                  <MDBDropdown>
                    <MDBDropdownToggle nav>
                      <MDBIcon icon="user-circle" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu right>
                      <MDBDropdownItem>
                        Welcome, {sessionStorage.getItem("firstName")}
                      </MDBDropdownItem>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem onClick={this.toggleMyAccount}>
                        My Account
                      </MDBDropdownItem>
                      <MDBDropdownItem onClick={this.toggleResetPassword}>
                        Reset Password
                      </MDBDropdownItem>
                      {sessionStorage
                        .getItem("adminOf")
                        .includes("VenueAdministrator") ||
                      sessionStorage
                        .getItem("adminOf")
                        .includes("LeasingAgent") ? (
                        <MDBDropdownItem>
                          <MDBNavLink to="/dashboard" className="accountSwitch">
                            {t("subnavigation.venueadmin")}
                          </MDBNavLink>
                        </MDBDropdownItem>
                      ) : (
                        ""
                      )}
                      <MDBDropdownItem>
                        <MDBNavLink to="/myaccount" className="accountSwitch">
                          Subscriber Account
                        </MDBNavLink>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <a
                          href="https://docs.roamingiq.com"
                          className="nav-link Ripple-parent accountSwitch"
                          target="_blank"
                        >
                          Knowledge Base
                        </a>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>

                  <MDBNavItem>
                    <MDBNavLink to={loginURL} onClick={this.onSignOutClick}>
                      {t("navigation.logout")}
                    </MDBNavLink>
                  </MDBNavItem>
                </div>
              </div>
            </div>
          </MDBNavbarNav>
        </MDBNavbar>
      </>
    );
  }
}

export default withTranslation()(Navigation);
