import React, { Component } from "react";

import {
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBModal,
  toast,
  MDBModalFooter,
  MDBBtn
} from "mdbreact";

import EquipmentErrorLog from "../../../../components/NetworkMgmt/Support/EquipmentErrorLog";

import PendingGatewayVLANDisable from "../../../../components/NetworkMgmt/Support/PendingGatewayVLANDisable";

import PendingGatewayVLANEnable from "../../../../components/NetworkMgmt/Support/PendingGatewayVLANEnable";

import axios from "../../../../axios";

import { withTranslation } from "react-i18next";

class PropertyManagementNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddStaff: false,
      modalModifyStaff: false,
      loading: false,
      building: "All",
    };
  }

  componentDidMount() {
    this.getEquipmentErrorLog();
    this.getPendingGatewayVLANDisable();
    this.getPendingGatewayVLANEnable();
  }

  togglEquipmentErrorLog = () => {
    this.setState({
      modalEquipmentErrorLog: !this.state.modalEquipmentErrorLog,
    });
  };

  togglelistPendingGatewayVLANDisable = () => {
    this.setState({
      modallistPendingGatewayVLANDisable:
        !this.state.modallistPendingGatewayVLANDisable,
    });
  };

  togglelistPendingGatewayVLANEnable = () => {
    this.setState({
      modallistPendingGatewayVLANEnable:
        !this.state.modallistPendingGatewayVLANEnable,
    });
  };

  getEquipmentErrorLog = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    this.setState({
      equipmentErrorLog: [],
    });
    axios
      .post("/networkoperatorconsole/equipment/listEquipmentErrorLog", body, {
        headers: {},
      })
      .then(
        (response) => {
          this.setState({ equipmentErrorLog: response.data.Errors });
        },
        (error) => {
          console.log(
            "🚀 ~ file: SupportNavigation.js:68 ~ PropertyManagementNavigation ~ error",
            error
          );
        }
      );
  };

  getPendingGatewayVLANDisable = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    this.setState({
      pendingGatewayVLANDisable: [],
    });
    axios
      .post(
        "/networkoperatorconsole/equipment/gateway/listPendingGatewayVLANDisable",
        body,
        {
          headers: {},
        }
      )
      .then(
        (response) => {
          this.setState({
            pendingGatewayVLANDisable: response.data.Subscriptions,
          });
        },
        (error) => {
          console.log(
            "🚀 ~ file: SupportNavigation.js:87 ~ PropertyManagementNavigation ~ error",
            error
          );
        }
      );
  };

  getPendingGatewayVLANEnable = () => {
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    this.setState({
      pendingGatewayVLANEnable: [],
    });
    axios
      .post(
        "/networkoperatorconsole/equipment/gateway/listPendingGatewayVLANEnable",
        body,
        {
          headers: {},
        }
      )
      .then(
        (response) => {
          this.setState({
            pendingGatewayVLANEnable: response.data.Subscriptions,
          });
        },
        (error) => {
          console.log(
            "🚀 ~ file: SupportNavigation.js:110 ~ PropertyManagementNavigation ~ error",
            error
          );
        }
      );
  };

  toggleConfirmSubscriberDeletion = (key) => {
    this.setState({
      modalConfirmSubscriberDeletion:
        !this.state.modalConfirmSubscriberDeletion,
    });
  };

  confirmDeleteSubscriber = () => {
    if (this.state.confirmDelete === "DELETE") {
      this.setState({
        isDeleting: true,
      });
      let body;
      body = {
        AccessToken: sessionStorage.getItem("AccessToken"),
        EmailAddress: this.state.emailSubscriber,
      };
      axios
        .post(
          "/networkoperatorconsole/admin/deleteSubscriberAccountPermanently",
          body,
          {
            headers: {},
          }
        )
        .then(
          (response) => {
            toast.success("Subscriber Deleted!", {
              closeButton: false,
            });
            this.toggleConfirmSubscriberDeletion();
            this.setState({
              isDeleting: false,
            });
          },
          (error) => {
            toast.error(error.response.data.message, {
              closeButton: false,
            });
            this.setState({
              isDeleting: false,
            });
            this.cancelUploadedFile();
          }
        );
    } else {
      console.log("Please re-enter");
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    if (this.state.postMessage) {
      var h = document.getElementById("overlaySettings");
      h.style.display = "block";
      setTimeout(function () {
        h.style.display = "block";
      }, 5000);
      setTimeout(() => {
        h.style.display = "none";
        this.setState({ postMessage: "" });
      }, 6000);
    }
    return (
      <>
        {this.props.loading === true ? (
          <div className="overlay" id="overlay" style={{ display: "block" }}>
            <div className="overlayMessage">
              <div className="spinner-border spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <MDBModal
          isOpen={this.state.modalConfirmSubscriberDeletion}
          toggle={this.toggleConfirmSubscriberDeletion}
        >
          <MDBModalHeader toggle={this.toggleConfirmSubscriberDeletion}>
            Confirm Subscriber to be Deleted
          </MDBModalHeader>
          <MDBModalBody>
          <label htmlFor="defaultFormLoginEmailEx" className="black-text">
                  Email Address of Subscriber
                </label>
                <br />
            <input
              required
              name="emailSubscriber"
              id="emailSubscriber"
              className="form-control"
              onChange={this.changeHandler}
              value={this.state.emailSubscriber}
            />
            <br/>
            <p className="redText">
             WARNING: THIS ACTION CANNOT BE UNDONE. This will delete all records of this subscriber, including their Wi-Fi password, payment information, and all historical records. It will be as though this user never existed.
            </p>
            <input
              required
              name="confirmDelete"
              id="confirmDelete"
              className="form-control"
              onChange={this.changeHandler}
              value={this.state.address1}
              placeholder="Type DELETE"
            />
          </MDBModalBody>
          <MDBModalFooter>
            <div
              className="text-right submitButton pt-2"
              key={this.state.theInputKey || ""}
            >
              <MDBBtn
                color="primary"
                onClick={this.confirmDeleteSubscriber}
                disabled={this.state.confirmDelete === "DELETE" ? false : true}
              >
                {this.state.isDeleting === true ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Confirm"
                )}
              </MDBBtn>
              <MDBBtn
                color="primary"
                onClick={this.toggleConfirmSubscriberDeletion}
              >
                Close
              </MDBBtn>
            </div>
          </MDBModalFooter>
        </MDBModal>
        <MDBModalHeader toggle={this.props.toggleSupportSettings}>
          <h4>Support Settings</h4>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="managementModal">
            <>
              <MDBRow>
                <MDBCol size='12' md='6' lg='4'>
                  <div
                    className="managementModalButton"
                    onClick={this.togglEquipmentErrorLog}
                  >
                    <MDBIcon icon="server" />
                    <p>Equipment Error Log</p>
                  </div>
                </MDBCol>
                <MDBCol size='12' md='6' lg='4'>
                  <div
                    className="managementModalButton"
                    onClick={this.togglelistPendingGatewayVLANDisable}
                  >
                    <MDBIcon icon="envelope-open" />
                    <p>
                      Pending Gateway
                      <br /> VLAN Disable
                    </p>
                  </div>
                </MDBCol>

                <MDBCol size='12' md='6' lg='4'>
                  <div
                    className="managementModalButton"
                    onClick={this.togglelistPendingGatewayVLANEnable}
                  >
                    <MDBIcon icon="exclamation-circle" />
                    <p>
                      Pending Gateway
                      <br /> VLAN Enable
                    </p>
                  </div>
                </MDBCol>
                <MDBCol size='12' md='6' lg='4'>
                  <div
                    className="managementModalButton"
                    onClick={this.toggleConfirmSubscriberDeletion}
                  >
                    <MDBIcon icon="ban" />
                    <p>Delete<br/> Subscriber</p>
                  </div>
                </MDBCol>
              </MDBRow>
            </>
          </div>
        </MDBModalBody>
        <MDBModal
          isOpen={this.state.modalEquipmentErrorLog}
          toggle={this.toggle}
          size="fluid"
          className="form"
          centered
        >
          <EquipmentErrorLog
            equipmentErrorLog={this.state.equipmentErrorLog}
            togglEquipmentErrorLog={this.togglEquipmentErrorLog}
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.modallistPendingGatewayVLANDisable}
          toggle={this.toggle}
          size="lg"
          className="form"
          centered
        >
          <PendingGatewayVLANDisable
            pendingGatewayVLANDisable={this.state.pendingGatewayVLANDisable}
            togglelistPendingGatewayVLANDisable={
              this.togglelistPendingGatewayVLANDisable
            }
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.modallistPendingGatewayVLANEnable}
          toggle={this.toggle}
          size="lg"
          className="form"
          centered
        >
          <PendingGatewayVLANEnable
            pendingGatewayVLANEnable={this.state.pendingGatewayVLANEnable}
            togglelistPendingGatewayVLANEnable={
              this.togglelistPendingGatewayVLANEnable
            }
          />
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(PropertyManagementNavigation);
