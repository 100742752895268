import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBRow,
    MDBCol,
} from "mdbreact";

import axios from "../../../../axios";

import { DateTimeInput } from "semantic-ui-calendar-react";

import PhoneInput, {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
} from "react-phone-number-input";

import { withTranslation } from "react-i18next";

import $ from "jquery";

import SelectSearch, { fuzzySearch } from "react-select-search";




class AddNewResident extends Component {
	constructor(props) {
		super(props);
		this.handleDayChangeEnd = this.handleDayChangeEnd.bind(this);
		this.state = {
			Email: "",
			sms: false,
			voice: false,
			dateStartToday: Intl.DateTimeFormat("en-US", {
				month: "2-digit",
				day: "2-digit",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
			}).format(new Date()),
			dateEnd: null,
			invalidPhone: null,
			loading: false,
			userFound: false,
			userFoundWithPhone: false,
			endDate: null,
			invalidDateEnd: null,
			invalidEmail: null,
			email: "",
			optionTemplateUnits: [],
			optionTemplateBuilding: [],
			optionTemplateFloor: [],
			languageOptions: [
				{ value: "en-us", name: "English" },
				{ value: "es-xl", name: "Spanish" },
			],
			loading_units: false,
		};
	}

	componentDidMount() {
		this.getVenueDetails(this.props.venueID);
	}

	validateEmail = (email) => {
		var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
		this.setState({
			invalidPhone: undefined,
			userFound: false,
			userFoundWithPhone: false,
		});

		if (!this.state.email.match(mailformat)) {
			this.setState({
				invalidEmail: "Please provide a valid email address",
			});
			$(".emailInput").addClass("is-invalid");
		} else {
			this.setState({ invalidEmail: undefined });
			$(".emailInput").removeClass("is-invalid");
		}
		this.setState({ loading: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: email,
			VenueID: this.props.venueID,
		};
		axios
			.post("/venuemgmtconsole/subscribers/validateEmailAddress", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					firstName: response.data.GivenName,
					lastName: response.data.FamilyName,
					selectedLanguage: response.data.locale,
					invalidPhone: undefined,
					userFound: true,
				});
				if (response.data["PhoneNumber.E164"] === "") {
					this.setState({ userFoundWithPhone: false });
				} else {
					this.setState({
						phoneNumber: response.data["PhoneNumber.E164"],
						userFoundWithPhone: true,
					});
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ error: true });
				this.setState({ loading: false });
				this.setState({ userFound: false });
			});
	};

	getVenueDetails = (index) => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			VenueID: index,
			UserTimeZone: new Date().getTimezoneOffset(),
		};
		this.setState({
			unitData: undefined,
			expiringData: undefined,
			searchData: undefined,
			value: "",
			loading_units: true,
			duration: undefined,
		});
		axios
			.post("/venuemgmtconsole/venues/listVenueDetails", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					unitData:
						response.data[index][
							"Units-Building-Floor-List-GuestNetworks"
						],
					timezone: response.data[index]["TimeZone"], //+4
					duration:
						response.data[index][
							"DefaultSubscriptionDurationHours-Guests"
						],
				});
				let optionTemplateUnits = [];
				Object.keys(
					response.data[index][
						"Units-Building-Floor-List-GuestNetworks"
					]
				).map((oneKey, i) => {
					optionTemplateUnits.push({ value: oneKey, name: oneKey });
					return true;
				});

				this.setState({
					optionTemplateUnits: optionTemplateUnits,
					loading_units: false,
				});
				var offset =
					(new Date().getTimezoneOffset() +
						response.data[index]["TimeZone"] * 60) *
					60 *
					1000;
				let offsetTimestamp = new Date().getTime() + offset;
				let offsetDateStart = new Date(offsetTimestamp);
				this.setState({
					dateStart: Intl.DateTimeFormat("en-US", {
						month: "2-digit",
						day: "2-digit",
						year: "numeric",
						hour: "numeric",
						minute: "numeric",
					}).format(offsetDateStart),
				});
				let offsetTimestampNow = new Date().getTime() + offset;
				let offsetDateStartNow = new Date(offsetTimestampNow);
				this.setState({
					dateStart: Intl.DateTimeFormat("en-US", {
						month: "2-digit",
						day: "2-digit",
						year: "numeric",
						hour: "numeric",
						minute: "numeric",
					}).format(offsetDateStart),
				});
				this.setState({
					dateStartNow: Intl.DateTimeFormat("en-US", {
						month: "2-digit",
						day: "2-digit",
						year: "numeric",
						hour: "numeric",
						minute: "numeric",
					}).format(offsetDateStartNow),
				});
				this.setEndDate(
					offsetDateStart,
					this.state.duration,
					response.data[index]["TimeZone"]
				);
			})
			.catch((error) => {
				this.getVenueDetails(index);
				this.setState({
					error: true,
					loading_units: false,
				});
			});
	};

	resetBuildingForm = (value) => {
		this.setState({
			buildingForm: (
				<MDBSelect
					id="selectBuilding"
					getValue={this.handleSelectChangeBuilding}
					className="form-control"
				>
					<MDBSelectInput
						className="selectInput selectBuilding"
						selected="Choose your building"
					/>
				</MDBSelect>
			),
		});
		this.setState({
			floorForm: (
				<MDBSelect
					id="selectFloor"
					getValue={this.handleSelectChangeFloor}
					className="form-control"
				>
					<MDBSelectInput
						className="selectInput selectFloor"
						selected="Choose your floor"
					/>
				</MDBSelect>
			),
		});
		setTimeout(() => {
			this.handleSelectChangeUnit(value);
		}, 1);
	};

	handleSelectChangeUnit = (value) => {
		this.setState({
			selectedUnit: value,
			optionTemplateFloor: [],
			selectedBuilding: undefined,
			selectedFloor: undefined
		});

		let optionTemplateBuilding = [];
		Object.keys(this.state.unitData[value]).map((oneKey, i) => {
			if (oneKey === "DEFAULT") {
				optionTemplateBuilding.push({ value: oneKey, name: oneKey });
			} else {
				optionTemplateBuilding.push({ value: oneKey, name: oneKey });
			}
			return true;
		});
		this.setState({
			optionTemplateBuilding: optionTemplateBuilding,
		});
	};

	resetFloorForm = (value) => {
		setTimeout(() => {
			this.handleSelectChangeBuilding(value);
		}, 1);
	};

	handleSelectChangeBuilding = (value) => {
		this.setState({ selectedBuilding: value });

		let optionTemplateFloor = [];
		Object.keys(this.state.unitData[this.state.selectedUnit][value]).map(
			(oneKey, i) => {
				if (oneKey === "DEFAULT") {
					optionTemplateFloor.push({ value: oneKey, name: oneKey });
				} else {
					optionTemplateFloor.push({ value: oneKey, name: oneKey });
				}
				return true;
			}
		);

		this.setState({
			optionTemplateFloor: optionTemplateFloor,
		});
	};

	handleSelectChangeFloor = (value) => {
		this.setState({
			selectedFloor: value,
			unitID_internal:
				this.state.unitData[this.state.selectedUnit][
					this.state.selectedBuilding
				][value]["UnitID_internal"],
		});
	};

	handleSelectChangeLanguage = (value) => {
		this.setState({ selectedLanguage: value });
	};

	submitHandler = (event) => {
		event.preventDefault();
		event.target.className += " was-validated";
		$("#selectUnit").removeClass("is-invalid");
		$("#selectBuilding").removeClass("is-invalid");
		$("#selectFloor").removeClass("is-invalid");
		$("#selectLanguage").removeClass("is-invalid");
		if (this.state.selectedUnit === undefined) {
			this.setState({ invalidUnit: "Please provide a valid unit" });
			$("#selectUnit").addClass("is-invalid");
		} else {
			$("#selectUnit").addClass("is-valid");
		}
		if (this.state.selectedBuilding === undefined) {
			this.setState({ invalidBuilding: "Please provide a valid unit" });
			$("#selectBuilding").addClass("is-invalid");
		} else {
			$("#selectBuilding").addClass("is-valid");
		}
		if (this.state.selectedFloor === undefined) {
			this.setState({ invalidFloor: "Please provide a valid floor" });
			$("#selectFloor").addClass("is-invalid");
		} else {
			$("#selectFloor").addClass("is-valid");
		}
		if (this.state.selectedLanguage === undefined) {
			this.setState({
				invalidLanguage: "Please provide a valid language",
			});
			$("#selectLanguage").addClass("is-invalid");
		} else {
			$("#selectLanguage").addClass("is-valid");
		}
		if (this.state.email === undefined) {
			this.setState({
				invalidEmail: "Please provide a valid email address",
			});
		}
		if (
			this.state.invalidPhone === null ||
			this.state.invalidPhone !== undefined
		) {
			this.setState({
				invalidPhone: "Please provide a valid phone number",
			});
			$(".PhoneInput").addClass("is-invalid");
		} else {
			$(".PhoneInput").addClass("is-valid");
		}

		if (
			this.state.invalidEmail === undefined &&
			this.state.firstName !== undefined &&
			this.state.lastName !== undefined &&
			this.state.selectedLanguage !== undefined &&
			this.state.selectedUnit !== undefined &&
			this.state.selectedBuilding !== undefined &&
			this.state.selectedFloor !== undefined &&
			this.state.invalidDate === undefined &&
			this.state.invalidDateEnd === null
		) {
			this.props.submitHandlerGuest(
				this.state.dateEnd,
				this.state.email,
				this.state.firstName,
				this.state.lastName,
				this.state.phoneNumber,
				this.state.selectedLanguage,
				this.state.selectedUnit,
				this.state.selectedBuilding,
				this.state.selectedFloor,
				this.state.sms,
				this.state.voice,
				true,
				this.state.dateStart,
				this.state.unitID_internal
			);
		}
	};

	toggleCheckboxValueSMS = () => {
		this.setState({ sms: !this.state.sms });
	};

	toggleCheckboxValueVoice = () => {
		this.setState({ voice: !this.state.voice });
	};

	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	changeHandlerPhone = (event) => {

		this.setState({ phoneNumber: event });
		var value = event
			? isPossiblePhoneNumber(event)
				? undefined
				: "Please provide a valid phone number"
			: "Phone number required";
		this.setState({ invalidPhone: value });
	};
	createMask = () => {
		this.setState({
			phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
		});
	};

	setEndDate = (dateStart, duration, timezone) => {
		if (dateStart !== null) {
			let endDateHour
			if (duration >= 24 || duration === 0) {
				endDateHour = new Date(dateStart.valueOf()).getHours() + 24;
			} else {
				endDateHour =
					new Date(dateStart.valueOf()).getHours() + duration;
			}
			var endDate = new Date(dateStart).setHours(endDateHour);
			let maxDateDay;
			let maxDate;
			if (duration === 0) {
				maxDateDay = new Date(dateStart.valueOf());
				maxDate = new Date(dateStart).setHours(maxDateDay);
			} else {
				maxDateDay =
					new Date(dateStart.valueOf()).getHours() + duration;
				maxDate = new Date(dateStart).setHours(maxDateDay);
			}

			this.setState({ maxDate: maxDate });
			if (isNaN(endDate) !== true) {
				this.setState({ dateEnd: null });

				var endDateISO = new Date(endDate).toISOString();
				if (dateStart !== null) {
					this.setState({
						dateEnd: Intl.DateTimeFormat("en-US", {
							month: "2-digit",
							day: "2-digit",
							year: "numeric",
							hour: "numeric",
							minute: "numeric",
						}).format(new Date(endDateISO)),
					});
				}
			}
		}
	};

	handleDayChangeEnd = (event, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		var isISO = value;
		if (new Date(isISO) > new Date(this.state.maxDate)) {
			this.setState({
				invalidDateEnd: "Date cannot exceed 5 days for a Guest",
			});
		} else {
			if (isNaN(new Date(isISO)) === false) {
				this.setState({ invalidDateEnd: null });
			} else {
				this.setState({
					invalidDateEnd:
						"Please provide a valid date in this format MM/DD/YYYY H:MM AM/PM",
				});
			}
		}
	};
	handleChange = (event, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
	};
	render() {
		const { t } = this.props;
		const FORMAT = "MM/dd/yyyy";
		return (
			<>
				{this.state.loading_units === true ? (
					<div id="overlay" style={{ display: "block" }}>
						<div className="overlayMessage">
							<div
								className="spinner-border spinner-border"
								role="status"
							>
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<MDBModalHeader toggle={this.props.toggleAddNewGuest}>
					<h4>
						{this.props.venueType === "HOTSPOT"
							? "Add New Subscriber"
							: t("addnewguest.title")}
					</h4>
					<p>{t("addnewresident.required")}</p>
				</MDBModalHeader>
				<MDBModalBody>
					<form
						className="needs-validation"
						onSubmit={this.submitHandler}
						noValidate
						id="form2"
					>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addnewresident.email")}
								</label>
								<input
									required
									id="email"
									className="form-control emailInput"
									onBlur={(event) =>
										this.validateEmail(this.state.email)
									}
									onChange={(event) =>
										this.setState({
											email: event.target.value,
										})
									}
									value={this.state.email}
									error={
										this.state.invalidEmail !== undefined
											? "Invalid email address"
											: "Email address required"
									}
								/>
								{this.state.invalidEmail !== undefined ? (
									<div className="invalid">
										{this.state.invalidEmail}
									</div>
								) : (
									""
								)}
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addnewresident.firstname")}
								</label>
								<input
									required
									name="firstName"
									id="firstName"
									className={
										this.state.loading === true
											? "form-control loading"
											: "form-control"
									}
									onChange={this.changeHandler}
									value={this.state.firstName}
									disabled={this.state.userFound}
								/>
								<div className="invalid-feedback">
									Please provide a valid first name.
								</div>
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addnewresident.lastname")}
								</label>
								<input
									required
									className={
										this.state.loading === true
											? "form-control loading"
											: "form-control"
									}
									name="lastName"
									id="lastName"
									onChange={this.changeHandler}
									value={this.state.lastName}
									disabled={this.state.userFound}
								/>
								<div className="invalid-feedback">
									Please provide a valid last name.
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addnewguest.phone")}
								</label>
								<PhoneInput
									placeholder={t("addnewresident.enterphone")}
									value={this.state.phoneNumber}
									format={FORMAT}
									onChange={this.changeHandlerPhone}
									name="phoneNumber"
									id="phoneNumber"
									className={
										this.state.loading === true
											? "form-control loading"
											: "form-control"
									}
									defaultCountry="US"
									required
									error={
										this.state.phoneNumber
											? isValidPhoneNumber(
													this.state.phoneNumber
											  )
												? undefined
												: "Invalid phone number"
											: "Phone number required"
									}
									disabled={this.state.userFoundWithPhone}
								/>
								{this.state.invalidPhone !== null ? (
									<div className="invalid">
										{this.state.invalidPhone}
									</div>
								) : (
									""
								)}
							</MDBCol>
							<MDBCol col="6">
								<div>
									<label
										htmlFor="defaultFormLoginEmailEx"
										className="black-text"
									>
										{t("addnewresident.language")}
									</label>
									<SelectSearch
										id="selectLanguage"
										search={true}
										filterOptions={fuzzySearch}
										options={this.state.languageOptions}
										isOptionSelected={true}
										classNamePrefix="language-select"
										onChange={
											this.handleSelectChangeLanguage
										}
										value={this.state.selectedLanguage}
										placeholder={
											this.state.selectedLanguage !==
											undefined
												? this.state
														.selectedLanguage ===
												  "en-us"
													? "English"
													: "Spanish"
												: "Select..."
										}
									/>
									<div className="invalid-feedback">
										Please provide a valid language.
									</div>
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col="6" sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{this.props.venueType === "HOTSPOT"
										? "Subscriber Network *"
										: t("addnewguest.unit")}
								</label>
								<SelectSearch
									id="selectUnit"
									search={true}
									filterOptions={fuzzySearch}
									options={this.state.optionTemplateUnits}
									onChange={this.handleSelectChangeUnit}
									isOptionSelected={true}
									inputId="unitArray"
									classNamePrefix="unit-select form-control"
									placeholder="Select..."
									value={this.state.selectedUnit}
								/>
								<div className="invalid-feedback">
									Please provide a unit number.
								</div>
							</MDBCol>
							<MDBCol col="6"></MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addnewresident.building")}
								</label>
								<SelectSearch
									id="selectBuilding"
									options={this.state.optionTemplateBuilding}
									search={true}
									filterOptions={fuzzySearch}
									onChange={this.handleSelectChangeBuilding}
									isOptionSelected={true}
									inputId="buildingArray"
									classNamePrefix="building-select"
									placeholder="Select..."
									value={this.state.selectedBuilding}
								/>
								<div className="invalid-feedback">
									Please provide a building.
								</div>
							</MDBCol>
							<MDBCol col="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addnewresident.floor")}
								</label>
								<SelectSearch
									ref={this.floorSelect}
									id="selectFloor"
									search={true}
									filterOptions={fuzzySearch}
									options={this.state.optionTemplateFloor}
									onChange={this.handleSelectChangeFloor}
									isOptionSelected={true}
									inputId="floorArray"
									classNamePrefix="floo-select"
									placeholder="Select..."
									emptyMessage="Select..."
									value={this.state.selectedFloor}
								/>
								<div className="invalid-feedback">
									Please provide a floor.
								</div>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addnewresident.servicestart")}
								</label>
								<br />
								<DateTimeInput
									dateTimeFormat="MM/DD/YYYY, h:mm A"
									timeFormat="ampm"
									name="dateStart"
									placeholder="MM/DD/YYYY"
									value={this.state.dateStart}
									iconPosition="left"
									onChange={this.handleChange}
									onBlur={() =>
										this.setEndDate(
											this.state.dateStart,
											this.state.duration,
											this.state.timezone
										)
									}
									className="dateTimePicker"
									disableMinute="true"
									minDate={this.state.dateStartNow}
									maxDate={new Date(this.state.maxDate)}
								/>
								<label
									for="serviceStart"
									className="black-text"
									style={{
										fontSize: "11px",
										position: "relative",
										top: "-5px",
										left: "0px",
									}}
								>
									{this.props.venueType === "HOTSPOT"
										? "Subscriber can use their Wi-Fi password as of this date"
										: t("addnewguest.servicestartlabel")}
								</label>
								{this.state.invalidDate !== null ? (
									<div className="invalid">
										{this.state.invalidDate}
									</div>
								) : (
									""
								)}
							</MDBCol>
							<MDBCol sm="12" lg="6">
								<label
									htmlFor="defaultFormLoginEmailEx"
									className="black-text"
								>
									{t("addnewresident.serviceend")}
								</label>
								<br />
								<DateTimeInput
									dateTimeFormat="MM/DD/YYYY, h:mm A"
									timeFormat="ampm"
									name="dateEnd"
									placeholder="MM/DD/YYYY"
									value={
										this.state.dateEnd !== null &&
										this.state.invalidDateEnd === null
											? this.state.dateEnd
											: this.state.dateEnd
									}
									iconPosition="left"
									onChange={this.handleChange}
									className="dateTimePicker"
									disableMinute="true"
									maxDate={
										this.state.duration === 0
											? "0"
											: new Date(this.state.maxDate)
									}
									minDate={new Date(this.state.dateStart)}
								/>

								<label
									className="black-text"
									style={{
										fontSize: "11px",
										position: "relative",
										top: "-5px",
										left: "0px",
									}}
								>
									{t("addnewguest.serviceendlabel")}
								</label>
								{this.state.invalidDateEnd !== null ? (
									<div className="invalid">
										{this.state.invalidDateEnd}
									</div>
								) : (
									""
								)}
							</MDBCol>
						</MDBRow>
						<div className="text-right submitButton">
							<MDBBtn color="danger" type="submit">
								{this.props.loading === true ? (
									<div
										className="spinner-border spinner-border-sm"
										role="status"
									>
										<span className="sr-only">
											Loading...
										</span>
									</div>
								) : this.props.venueType === "HOTSPOT" ? (
									"Add Subscriber"
								) : (
									t("addnewguest.addguest")
								)}
							</MDBBtn>
						</div>
					</form>
				</MDBModalBody>
			</>
		);
	}
}

export default withTranslation()(AddNewResident);