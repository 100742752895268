/* Find Deals screen */

import React, { useState, useEffect, Suspense } from "react";

import { MDBBtn, MDBModal, MDBModalBody } from "mdbreact";

import axios from "../../axios";

import Footer from "../../components/Common/Footer";

import brandsData from "../../brands/common/brands.json";

import queryString from "query-string";




let CheckOutComponent;

let Logo;

let BrandName;


brandsData.forEach(
  ({ name, url, component_directory_name, brand_directory_name, main_logo }) => {
    if (url.includes(window.location.hostname)) {
     /* eslint-disable no-unused-expressions */
      // import("../../brands/" + brand_directory_name + "/css/style.scss");
      CheckOutComponent = React.lazy(() =>
        import(
          "../../brands/" +
            component_directory_name +
            "/components/UpdateAccount/CheckOut"
        )
      );
      Logo = "/" + brand_directory_name + "/" + main_logo;
      BrandName = name;
    }
  }
);

export default function App(props) {
  const success = queryString.parse(window.location.search);
  const [clientSecret, setClientSecret] = useState("");

  const [stripePromise, setStripePromise] = useState("");
  const [state, setState] = useState({
        modalBillingDetails: false
    });
  useEffect(() => {
    let BillingCycleRecurrence;
    !sessionStorage.getItem("daysofservice") ? BillingCycleRecurrence = 0 : BillingCycleRecurrence = sessionStorage.getItem("daysofservice")
    let data;
      if(!sessionStorage.getItem("newServicePlanID")){
        data = {
          "UnitID_internal": sessionStorage.getItem("unitID_internalSubscriber"),
          "StripeClientSecret": sessionStorage.getItem("stripeClientSecretSubscriber"),
          "EmailAddress": sessionStorage.getItem("emailSubscriber"),


          "VenueID": sessionStorage.getItem("venueIDSubscriber"),
            "StartDateTimeUTC.ISO8601": new Date(sessionStorage.getItem("dateStart")).toISOString(),  
            "locale": "en-us",
            "BillingCycleRecurrence":BillingCycleRecurrence,
            "updatePaymentMethod":true,
        };
      }else{
        data = {
          "ServicePlanID": sessionStorage.getItem("newServicePlanID"),
          "UnitID_internal": sessionStorage.getItem("unitID_internalSubscriber"),
          "StripeClientSecret": sessionStorage.getItem("stripeClientSecretSubscriber"),
          "EmailAddress": sessionStorage.getItem("emailSubscriber"),


          "VenueID": sessionStorage.getItem("venueIDSubscriber"),
            "StartDateTimeUTC.ISO8601": new Date(sessionStorage.getItem("dateStart")).toISOString(),  
            "locale": "en-us",
            "BillingCycleRecurrence":BillingCycleRecurrence,
            "updatePaymentMethod":true,
        };
      }
        
        axios
            .post("/networkoperatorconsole/shoppingcart/requestSubscription", data, {
                headers: {},
            })
            .then((response) => {
                setClientSecret(response.data.StripeClientSecret);
                sessionStorage.setItem("clientSecret", response.data.StripeClientSecret);
                sessionStorage.setItem("nextPaymentDate", response.data.NextPaymentDate);
                sessionStorage.setItem("proratedPricePerHour", response.data["ProratedPricePerHour.USD"]);
                sessionStorage.setItem("unbilledHoursTilNextPaymentDate", response.data.UnbilledHoursTillNextPaymentDate);
                sessionStorage.setItem("trialEndDate", response.data.TrialEndDate);
                sessionStorage.setItem("firstInvoiceDate", response.data["FirstInvoiceDateUTC.ISO8601"]);
                setStripePromise(response.data.StripePublishableKey);
                setState({billingDetails: response.data});
            })
            .catch((error) => {
                console.log("🚀 ~ file: CheckOut.js:98 ~ useEffect ~ error", error)
                setState({
                  errorMessage: error.response.data.message
              })
            });

  }, []);

  function toggleBillingDetails(){
    setState({
      modalBillingDetails: !state.modalBillingDetails,
    });
  };

  function returnToDashboard(){
    window.location.href = "/"+success.ui;
  }

  return (
      <>
      {state.errorMessage !== undefined ? (
                    <div className="overlay" style={{ display: "block" }}>
                        <div class="overlayMessage">
                        {state.errorMessage}
                        <div className="nextButton">
                            <br />
                            <a
                            className="btn Ripple-parent btn-default"
                            href={"/"+success.ui}
                            rel="noreferrer"
                            >
                            Return
                            </a>
                        </div>
                        </div>

                    </div>
                    ) : ""}
        <Suspense fallback={<div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>}>
          {success.success ? (
                        <MDBModal isOpen={true} centered>
            <MDBModalBody className="updatePayment">
              <h2>Congratulations!</h2>
              <p>Your payment method has been updated.</p>
                <MDBBtn color="danger" onClick={returnToDashboard}>
                  Ok
                </MDBBtn>
            </MDBModalBody>
          </MDBModal>
                    ) : "" }         
          <CheckOutComponent
                  stripePromise = {stripePromise}
                  clientSecret = {clientSecret}
                  logo={props.logoLogin}
                  brand={props.brand}
                  toggleBillingDetails = {toggleBillingDetails}
                  modalBillingDetails = {state.modalBillingDetails}
                  billingDetails = {state.billingDetails}
                />
          <Footer />
        </Suspense>
      </>
    );
  }