import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
  const { t } = useTranslation();
  let userAttributes;
  let i = 0;

  let datatable;
  [datatable] = React.useState("");
  if (props.operatorKeypoolList !== undefined) {
    userAttributes = [];
    for (const key of Object.keys(props.operatorKeypoolList)) {
      userAttributes.push({
        ssid: props.operatorKeypoolList[key]["ssid"],
        scope: props.operatorKeypoolList[key]["Scope"],
        AuthenticationMechanism: props.operatorKeypoolList[key]["AuthenticationMechanism"],
        connectedVenueCount: props.operatorKeypoolList[key]["ConnectedVenueCount"],
        KeyCount: props.operatorKeypoolList[key]["KeyCount"],
        SubscriberCount: props.operatorKeypoolList[key]["SubscriberCount"],
        InactiveSubscriberCount: props.operatorKeypoolList[key]["InactiveSubscriberCount"],
        displayOrder: props.operatorKeypoolList[key]["DisplayOrder"],
        dateAdded: Intl.DateTimeFormat("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
        }).format(new Date(props.operatorKeypoolList[key]["dateAddedUTC.ISO8601"])),
        clickEvent: () => props.getKeypoolInfo(key),
      });
      i = i + 1;
    }
  }

  datatable = ({
    columns: [
      {
        label: "SSID",
        field: "ssid",
      },
      {
        label: "Scope",
        field: "scope",
      },
      {
        label: "Auth Mechanism",
        field: "AuthenticationMechanism",
      },
      {
        label: "Connected Venues",
        field: "connectedVenueCount",
      },
      {
        label: "Key Count",
        field: "KeyCount",
      },
      {
        label: "Subscriber Count",
        field: "SubscriberCount",
      },
      {
        label: "Inactive Subscriber Count",
        field: "InactiveSubscriberCount",
      },
      {
        label: "Display Order",
        field: "displayOrder"
      },
      {
        label: "Date Added",
        field: "dateAdded",
      },
    ],
    rows: userAttributes,
  });
  return (
    <MDBDataTableV5
      responsive
      hover
      data={datatable}
      searchBottom={false}
      paging={false}
      className="modifyStaff"
      info={false}
      noRecordsFoundLabel={t("recentservice.nomatches")}
    />
  );
}