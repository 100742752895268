import * as React from "react";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: "venueBandwidthData",
            delimiter: ",",
            utf8WithBom: true,
            fields: [
              "UnitID",
              "UnitBuilding",
              "UnitFloor",
              "Date",
              "outputMiB",
              "inputMiB",
              "outputGiB",
              "inputGiB",
            ],
          }}
        />
      </GridToolbarContainer>
    </Box>
  );
}

export default function Basic(props) {
  let userAttributes = [];
  let i = 0;
  let columns = [];
  let rows = [];
  if (props.usageData !== undefined) {
    userAttributes = [];
    for (const key of Object.keys(props.usageData)) {
      userAttributes.push({
        id: key,
        UnitID: props.usageData[key]["UnitID"].replace(/,/g, ""),
        UnitBuilding: props.usageData[key]["UnitBuilding"].replace(/,/g, ""),
        UnitFloor: props.usageData[key]["UnitFloor"].replace(/,/g, ""),
        Date:
          props.usageData[key]["UsageReportDateUTC.ISO8601"] !== ""
            ? props.usageData[key]["UsageReportDateUTC.ISO8601"].replace(
                /,/g,
                ""
              )
            : "",
        outputMiB: (
          props.usageData[key]["Acct-Output-Octets"] / 1048576
        ).toFixed(2),
        inputMiB: (props.usageData[key]["Acct-Input-Octets"] / 1048576).toFixed(
          2
        ),
        outputGiB: (
          props.usageData[key]["Acct-Output-Octets"] / 1073741824
        ).toFixed(2),
        inputGiB: (
          props.usageData[key]["Acct-Input-Octets"] / 1073741824
        ).toFixed(2),
      });
      i = i + 1;
    }
  }
  if (
    props.selectedUsageUnits === "mib" ||
    props.selectedUsageUnits === undefined
  ) {
    columns = [
      {
        headerName: "Unit",
        field: "UnitID",
        flex: 0.1,
        minWidth: 50,
      },
      {
        headerName: "Building",
        field: "UnitBuilding",
        flex: 0.1,
        minWidth: 50,
      },
      {
        headerName: "Floor",
        field: "UnitFloor",
        flex: 0.1,
        minWidth: 50,
      },
      {
        headerName: "Date",
        field: "Date",
        flex: 0.1,
        type: "dateTime",
        valueFormatter: (value) =>
          value &&
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(value)),
      },
      {
        headerName: "Output (MB)",
        field: "outputMiB",
        flex: 0.1,
        type: "number",
      },
      {
        headerName: "Input (MB)",
        field: "inputMiB",
        flex: 0.1,
        type: "number",
      },
    ];
  }

  if (props.selectedUsageUnits === "gib") {
    columns = [
      {
        headerName: "Unit",
        field: "UnitID",
        flex: 0.1,
        minWidth: 50,
      },
      {
        headerName: "Building",
        field: "UnitBuilding",
        flex: 0.1,
        minWidth: 50,
      },
      {
        headerName: "Floor",
        field: "UnitFloor",
        flex: 0.1,
        minWidth: 50,
      },
      {
        headerName: "Date",
        field: "Date",
        flex: 0.1,
        type: "dateTime",
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        headerName: "Output (GB)",
        field: "outputGiB",
        flex: 0.1,
        type: "number",
      },
      {
        headerName: "Input (GB)",
        field: "inputGiB",
        flex: 0.1,
        type: "number",
      },
    ];
  }
  rows = userAttributes;
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowHeight={({ id, densityFactor }) => {
          return 70 * densityFactor;
        }}
        hideFooterPagination={false}
        hideFooter={false}
        slots={{ toolbar: QuickSearchToolbar }}
      />
    </div>
  );
}
